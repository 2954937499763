import slug from "slugify";

export const Slugify = (input: string) =>
  slug(input, { replacement: "-", lower: true });

export const ReverseSlugify = (input: string) =>
  input
    .split("-")
    .join(" ")
    .toLocaleUpperCase();

export const ConvertCamelCaseToTitleCase = (input: string) =>
         input
           .replace(
             /([a-z])([A-Z])/g,
             (allMatches, firstMatch, secondMatch) =>
               firstMatch + " " + secondMatch
           )
           .toLowerCase()
           .replace(
             /([ -_]|^)(.)/g,
             (allMatches, firstMatch, secondMatch) =>
               (firstMatch ? " " : "") + secondMatch.toUpperCase()
           );