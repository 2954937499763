import {
  ConvertGasFlowRateToImperial,
  ConvertLiquidFlowRateToImperial
} from "./UnitOfMeasurementConverter";

export const CreateCurve = (data, plotPoints, isMetric) => {
  if (!isMetric) {
    data.dataPoints.forEach(p => {
      p["gas"] = ConvertGasFlowRateToImperial(p["gas"]);
      p["liquid"] = ConvertLiquidFlowRateToImperial(p["liquid"]);
    });
    plotPoints[0] = ConvertGasFlowRateToImperial(plotPoints[0]).toFixed(2)
    plotPoints[1] = ConvertLiquidFlowRateToImperial(plotPoints[1]).toFixed(2)
  }
  
  const scatterLabels = data["dataPoints"].map(x => {
    return {x: x["gas"].toFixed(2), y: x["liquid"].toFixed(2)}
  });
  return {
    datasets: [
      {
        label: "Operating Curve",
        data: scatterLabels,
        fill: false,
        lineTension: 0.1,
        borderColor: "white",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "white",
        pointBackgroundColor: "white",
        pointBorderWidth: 1,
        pointHoverRadius: 1,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 5,
        showLine: true,
      },

      {
        label: "Operating Point",
        data: [
          {
            x: plotPoints[0],
            y: plotPoints[1]
          }
        ],
        backgroundColor: "#8e1b30",
        borderColor: "#8e1b30",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBackgroundColor: "#8e1b30",
        pointBorderColor: "#8e1b30",
        pointBorderWidth: 3,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 3,
        pointRadius: 3,
        pointHitRadius: 3,
      }

    ]
  };
};
  