import React, { useState } from "react";
import { Form, Formik } from "formik";
import { validationSchema } from "./Validation";
import { Button } from "reactstrap";
import FormikControl from "../Formik/FormikControl";
import Api from "../../Helpers/Api";
import "../Project/Project.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Customer = props => {
  const api = new Api();
  toast.configure();
  const saveCustomer = async customer => {
    await api.saveCustomer(customer, props.token, 0,0).then(response => {
      if (response.status === 200) {
        toast.success("Customer Created", {
          position: toast.POSITION.TOP_RIGHT,
          onClose: () => window.location.reload(true)
        });
      }
      else if(response.status === 204) {
        toast.error(`Cannot add Customer with the name ${customer} as it already exists`, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  };

  const [customerName] = useState("");
  const initialValues = { customerName };

  const onSubmit = values => {
    return saveCustomer(values.customerName)
  };

  return (
    <div style={{ padding: "40px" }}>
      <div className="projectInfoTitle">Create a Customer</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        className="d-flex flex-column"
      >
        {formik => {
          return (
            <Form className="d-flex flex-column">
              <FormikControl
                control="input"
                type="text"
                label="Customer Name"
                name="customerName"
                styles="chartName"
                placeholder="Customer Name"
              />
              <Button
                color="danger"
                className="float-right"
                outline
                type="submit"
                size="md"
                style={{ marginBottom: "15px" }}
                disabled={!formik.isValid}
              >
                Create Customer
              </Button>
              <div className="formik-title" style={{"text-decoration": "none" }}>
                This page will refresh after Customer has been successfully created
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Customer;