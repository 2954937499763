import { NetworkRequestStatus, GetUsersTypes, SubmitUserTypes } from "../Enums";

//none means that a user can only view/manage an object's children, not the object itself.
//For example, if an energy Producer's permission is "none" for a user, that means that the user should be able to view only the projects
//for which they have a "manage" or "view" permission.
export enum PermissionsEnum {
  View = "view",
  Manage = "manage",
  None = "none"
}

export interface ProjectPermission {
  id: number;
  name: string;
  permission: PermissionsEnum;
}

export interface CustomerPermission {
  id: number;
  name: string;
  permission: PermissionsEnum;
  projects: ProjectPermission[];
}

export interface UserAccess {
  customers: CustomerPermission[];
}

export interface AppMetadata {
  freeTokensLeft: number;
  tokensLeft: number;
  access: UserAccess;
}

export interface SubmitUser {
  email: string;
  app_metadata: AppMetadata;
  token: string;
}

export interface User {
  email: string;
  app_metadata: AppMetadata;
}

export interface IGetUsersResult {
  users: User[];
  status: NetworkRequestStatus;
  error: string;
}

export interface ISubmittedUserResult {
  user: User;
  status: NetworkRequestStatus;
  error: string;
}

export interface IAddUserParams {
  CustomerName: string;
  ProjectName: string;
  token: string;
}

export interface IGetUsersInitialAction {
  type: GetUsersTypes;
}

export interface IGettingUsersAction {
  type: GetUsersTypes;
}

export interface IGotUsersAction {
  type: GetUsersTypes;
  users: User[];
}

export interface IGettingUsersErrorAction {
  type: GetUsersTypes;
  error: string;
}

export interface ISubmittingUserAction {
  type: SubmitUserTypes;
}

export interface ISubmittedUserAction {
  type: SubmitUserTypes;
  user: User;
}

export interface ISubmittedUserErrorAction {
  type: SubmitUserTypes;
  error: string;
}
  