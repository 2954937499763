import React, { useContext, useEffect, useState } from "react";
import ChartSettings from "./Chart/ChartSettings";
import RecommendationBox from "./Recommendation/RecommendationBox";
import { ProductContext } from "../Context/ProductContext";
import { ErrorContext } from "../Context/ErrorContext";
import NotFound from "./Common/NotFound";
import { ChartContext } from "../Context/ChartContext";
import { UserTypeContext } from "../Context/UserTypeContext";
import Chart from "./Chart/Chart";
import Counter from "./Counter";
import { AdvancedSettingsToggleContext } from "../Context/AdvancedSettingsToggleContext";
import { UserSettingsContext } from "../Context/UserSettingsContext";
import ChartSettingsImperial from "./Chart/ChartSettingsImperial";

const ChartPage = props => {
  const { product } = useContext(ProductContext);
  const { error } = useContext(ErrorContext);
  const { displayData } = useContext(ChartContext);
  const { userType } = useContext(UserTypeContext);
  const { isMetric } = useContext(UserSettingsContext);
  const [toggleAdvancedSettings, setToggleAdvancedSettings] = useState(false);

  
  return (
    <>
      <AdvancedSettingsToggleContext.Provider
        value={{
          toggleAdvancedSettings: toggleAdvancedSettings,
          setToggleAdvancedSettings: setToggleAdvancedSettings
        }}
      >
        <div className="projectHeaderChart">
          <div className="projectInfoTitle">Generate a Chart</div>
          <div className="projectHorizontalToggle">
            <span id="advanced-settings" className="input-container">
              Units: {isMetric ? "Metric" : "Imperial"}
            </span>
            <span id="advanced-settings" className="input-container">
              Advanced Settings:{" "}
            </span>
            <div
              className="projectInfoValue"
              style={{ margin: 0, width: "auto" }}
            >
              <label
                className="units-of-measure-toggle"
                style={{ height: "15px", width: "65px" }}
              >
                <input
                  type="checkbox"
                  value={toggleAdvancedSettings}
                  onChange={() => {
                    setToggleAdvancedSettings(!toggleAdvancedSettings);
                  }}
                />
                <span className="units-of-measure-slider" />
                <div className="units-of-measure-text-container">
                  <span
                    className={`units-of-measure-text${
                      toggleAdvancedSettings ? "--active" : ""
                    }`}
                  >
                    On
                  </span>
                  <span
                    className={`units-of-measure-text${
                      !toggleAdvancedSettings ? "--active" : ""
                    }`}
                  >
                    Off
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
        {userType && userType === "ProjectViewer" && <Counter />}
        {displayData ? (
          <Chart chartData={displayData} />
        ) : (
          <Chart chartData={null} />
        )}
        {error && error === "notFound" && <NotFound token={props.token}/>}
        {error === "none" && product && <RecommendationBox />}
        {isMetric ? (
          <ChartSettings token={props.token} />
        ) : (
          <ChartSettingsImperial token={props.token} />
        )}
        <div className="card-title" style={{ color: "var(--sandtinel-red)" }}>
          <strong>*** All fields are Required.</strong>
        </div>
      </AdvancedSettingsToggleContext.Provider>
    </>
  );
};

export default ChartPage;