import React from "react";
import UnitsOfMeasureToggle from "./UnitsOfMeasureToggle/UnitsOfMeasureToggle";

const ConfigPage = props => {
  return (
    <>
      <div className="projectInfoTitle">Project Info / Configuration</div>
      <UnitsOfMeasureToggle token={props.token} />
      <div className="clearfix" />
      <div className="card-title" style={{ color: "var(--sandtinel-red)" }}>
          <strong>Changing this toggle will refresh the application.</strong>
      </div>
      <div className="clearfix margin-bottom-20" />
    </>
  );
};

export default ConfigPage;