import * as axios from "axios";


export default class Api {
  constructor() {
    /*
                                                                                         this.api_token = localStorage.getItem('token');
                                                                                 */
    this.client = null;
    this.api_url = `${global.apiEndpoint}`;
  }

  init = () => {
    let headers = {
      "Content-Type": "application/json"
    };

    /*  if (token) {
                                                                                       headers.Authorization = `Bearer ${token}`;
                                                                                   }
                                                  */
    this.client = axios.create({
      baseURL: "http://localhost:5000/api/",
      timeout: 31000,
      headers: headers
    });

    return this.client;
  };

  
  // ============================   OPERATING CURVE API  =====================================

  generateRecommendedCurve = (
    pressure,
    temperature,
    gasFlowRate,
    liquidFlowRate,
    waterCutPercentage,
    sandSize,
    gasPseudoCriticalPressure,
    gasPseudoCriticalTemperature,
    gasMolecularWeight,
    unitOfMeasurement,
    token,
    country
  ) =>
    this.init().get(
      `${global.apiEndpoint}OperatingCurve/generate-recommended-curve?pressure=${pressure}&temperature=${temperature}&gasFlowRate=${gasFlowRate}&liquidFlowRate=${liquidFlowRate}&waterCutPercentage=${waterCutPercentage}&sandSize=${sandSize}&gasPseudoCriticalPressure=${gasPseudoCriticalPressure}&gasPseudoCriticalTemperature=${gasPseudoCriticalTemperature}&gasMolecularWeight=${gasMolecularWeight}&unitOfMeasurement=${unitOfMeasurement}&country=${country}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  generateCurveByRecommendationGuid = (recommendationGuid, token) =>
    this.init().get(
      `${global.apiEndpoint}OperatingCurve/generate-curve-by-recommendation-guid?recommendationGuid=${recommendationGuid}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  // ============================   CUSTOMERS API  ============================================

  getAllCustomersWithProjects = token =>
    this.init().get(
      `${global.apiEndpoint}Customer/all-customers-with-projects`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  getAllCustomers = token =>
    this.init().get(`${global.apiEndpoint}Customer/all-customers`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });

  saveCustomer = (data, token, customerId, projectId) =>
    this.init().post(
      `${global.apiEndpoint}Customer/create-a-customer?customerId=${customerId}&projectId=${projectId}`,
      `'${data}'`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  // ============================   USER API  ===============================================

  saveUserMetadata = (endpoint, token, userMetadata, customerId, projectId) =>
    this.init().post(
      `${global.apiEndpoint}Users/update-user-metadata?customerId=${customerId}&projectId=${projectId}`,
      { userMetadata: userMetadata },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  getUsers = (endpoint, token, customerId, projectId) =>
    this.init().get(
      `${global.apiEndpoint}Users?customerId=${customerId}&projectId=${projectId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  getUserByEmail = (endpoint, token, customerId, projectId, email) =>
    this.init().get(
      `${global.apiEndpoint}Users/get-user-by-email?customerId=${customerId}&projectId=${projectId}&email=${email}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  projectAdminAction = (
    endpoint,
    token,
    user,
    customer,
    project,
    customerId,
    projectId,
    isCreditCardPurchase,
    renterType,
    frontEndClientId
  ) =>
    this.init().post(
      `${global.apiEndpoint}Users/project-admin?customerId=${customerId}&projectId=${projectId}`,
      {
        customer: customer,
        customerId: customerId,
        project: project,
        projectId: projectId,
        user: { email: user.email, app_metadata: user.app_metadata },
        frontendClientId: frontEndClientId,
        isCreditCardPurchase: isCreditCardPurchase,
        renterType: renterType,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  customerAdminAction = (endpoint, token, user, customer, customerId, frontEndClientId) =>
    this.init().post(
      `${global.apiEndpoint}Users/customer-admin`,
      {
        customer: customer,
        customerId: customerId,
        user: { email: user.email, app_metadata: user.app_metadata },
        frontendClientId: frontEndClientId
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  // ============================   PROJECT API  ============================================

  getProjectByProjectId = (projectId, token) =>
    this.init().get(
      `${global.apiEndpoint}Project/get-project-by-projectId?projectId=${projectId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  saveProject = (data, token, customerId, projectId) =>
    this.init().post(
      `${global.apiEndpoint}Project/create-a-project?customerId=${customerId}&projectId=${projectId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

  // ============================   RECOMMENDATION API  ============================================

  saveRecommendation = (data, token, customerId, projectId, userType) =>
    this.init().post(
      `${global.apiEndpoint}Recommendation/create-a-recommendation?customerId=${customerId}&projectId=${projectId}&userType=${userType}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      } 
    );

  // ============================   STRIPE API  ============================================

  stripeSettings = token =>
    this.init().get(`${global.apiEndpoint}Stripe/publisher-key`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    });

  // ============================   EMAIL API  ============================================

  emailCFDSupport = (data, token) =>
    this.init().post(
      `${global.apiEndpoint}Email/send-email-to-cfd-support`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );
}