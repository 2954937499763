import {
  isCustomerAdmin,
  isCustomerViewer,
  isProjectAdmin,
  isProjectViewer,
  isSalesStaff,
  isSiteAdmin,
  isStaff
} from "../Components/Auth/accessControlService";

const DetermineUserType = async (apiEndpoint, token, projectId, customerId) => {
  if (customerId === undefined && projectId === undefined) {
    if (await isSiteAdmin(apiEndpoint, token)) return "SiteAdmin";
    else if (await isSalesStaff(apiEndpoint, token)) return "SalesStaff";
    else if (await isStaff(apiEndpoint, token)) return "Staff";
  }
  if (customerId !== undefined) {
    if (await isCustomerAdmin(apiEndpoint, token, customerId))
      return "CustomerAdmin";
    else if (await isCustomerViewer(apiEndpoint, token, customerId))
      return "CustomerViewer";
  }
  if (customerId !== undefined && projectId !== undefined) {
    if (await isProjectAdmin(apiEndpoint, token, customerId, projectId))
      return "ProjectAdmin";
    else if (await isProjectViewer(apiEndpoint, token, customerId, projectId))
      return "ProjectViewer";
  }
};

export default DetermineUserType;
