export const AppSettingsMetric = () => ({
  pressure: global.pressure,
  temperature: global.temperature,
  gasFlowRate: global.gasFlowRate,
  liquidFlowRate: global.liquidFlowRate,
  waterCutPercentage: global.waterCutPercentage,
  sandSize: global.sandSize,
  gasPseudoCriticalPressure: global.gasPseudoCriticalPressure,
  gasPseudoCriticalTemperature: global.gasPseudoCriticalTemperature,
  gasMolecularWeight: global.gasMolecularWeight
});

