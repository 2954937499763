import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
  Badge
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisH,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { ConvertCamelCaseToTitleCase } from "../../../Helpers/Slugify";
import { Button } from "react-bootstrap";

const UserManagementRow = props => {
  const [optionsDropdownToggle, setOptionsDropdownToggle] = useState(false);
  const [modifyTokensOption, setModifyTokensOption] = useState(false);
  const [modifyFreeTokensOption, setFreeModifyTokensOption] = useState(false);
  const [freeNumberOfTokens, setFreeNumberOfTokens] = useState(    props.user.app_metadata.freeTokensLeft);
  const [numberOfTokens, setNumberOfTokens] = useState(
    props.user.app_metadata.tokensLeft
  );
  const toggleOptions = () => {
    setOptionsDropdownToggle(!optionsDropdownToggle);
  };
  const renterColorTag =
    props.user.app_metadata.renterType === "active-renter"
      ? "warning"
      : props.user.app_metadata.renterType === "occasional-renter"
      ? "secondary"
      : props.user.app_metadata.renterType === "prospective-renter"
      ? "info"
      : "info";
  return (
    <>
      <tr className="user-management-row" key={props.user.email}>
        <td>{props.user.nickname ? props.user.nickname : props.user.name}</td>
        <td className={props.optionalClassName}>{props.user.email}</td>
        <td>
          {!props.user.app_metadata.renterType ? (
            <span>Prospective Renter</span>
          ) : (
            <Badge color={renterColorTag}>
              {ConvertCamelCaseToTitleCase(props.user.app_metadata.renterType)}
            </Badge>
          )}
        </td>
        <td>
          {props.user.email_verified ? (
            <span>
              <Badge color="success">
                Verified
                <FontAwesomeIcon icon={faCheckCircle} />{" "}
              </Badge>
            </span>
          ) : (
            <a
              onClick={() => props.emailInvite(props.user.email)}
              className="custom-link dark"
            >
              Resend Invite
            </a>
          )}
        </td>
        <td style={{ "text-align-last": "center", width: "30px" }}>
          {modifyTokensOption === false ? (
            <span>{props.user.app_metadata.tokensLeft}</span>
          ) : (
            <span className="curve-inner-container">
              <Button
                onClick={() => {
                  if (numberOfTokens - 1 >= 0)
                    setNumberOfTokens(numberOfTokens - 1);
                  else if (numberOfTokens - 1 === 0) setNumberOfTokens(0);
                }}
                className="token-changer"
              >
                -
              </Button>
              <input
                type="number"
                min="1"
                value={numberOfTokens}
                onChange={e => {
                  if(e.target.value > 0)
                    setNumberOfTokens(e.target.value);
                }}
                style={{
                  "max-width": "50px",
                  "text-align": "center",
                  margin: "0px 2px 0px 2px"
                }}
              />
              <Button
                onClick={() => {
                  setNumberOfTokens(numberOfTokens + 1);
                }}
                className="token-changer"
              >
                +
              </Button>
              <Button
                color="danger"
                style={{ "margin-left": "15px" }}
                outline
                size="sm"
                className="float-right"
                onClick={() => {
                  if (props.user.app_metadata.tokensLeft === numberOfTokens) {
                    setModifyTokensOption(false);
                    return;
                  }
                  props.user.app_metadata.tokensLeft = numberOfTokens;
                  props.modifyTokens(props.user);
                  setModifyTokensOption(false);
                }}
              >
                ✔️
              </Button>
            </span>
          )}
        </td>
        <td style={{ "text-align-last": "center", width: "30px" }}>
          {modifyFreeTokensOption === false ? (
            <span>{props.user.app_metadata.freeTokensLeft}</span>
          ) : (
            <span className="curve-inner-container">
              <Button
                onClick={() => {
                  if (freeNumberOfTokens - 1 >= 1)
                    setFreeNumberOfTokens(freeNumberOfTokens - 1);
                  else if (freeNumberOfTokens - 1 === 0)
                    setFreeNumberOfTokens(0);
                }}
                className="token-changer"
              >
                -
              </Button>
              <input
                type="number"
                value={freeNumberOfTokens}
                onChange={e => {
                  if(e.target.value > 0)
                    setFreeNumberOfTokens(e.target.value);
                }}
                style={{
                  "max-width": "50px",
                  "text-align": "center",
                  margin: "0px 2px 0px 2px"
                }}
              />
              <Button
                onClick={() => {
                  setFreeNumberOfTokens(freeNumberOfTokens + 1);
                }}
                className="token-changer"
              >
                +
              </Button>
              <Button
                color="danger"
                style={{ "margin-left": "15px" }}
                outline
                size="sm"
                className="float-right"
                onClick={() => {
                  if (
                    props.user.app_metadata.freeTokensLeft ===
                    freeNumberOfTokens
                  ) {
                    setFreeModifyTokensOption(false);
                    return;
                  }
                  props.user.app_metadata.freeTokensLeft = freeNumberOfTokens;
                  props.modifyTokens(props.user);
                  setFreeModifyTokensOption(false);
                }}
              >
                ✔️
              </Button>
            </span>
          )}
        </td>
        <td>
          <Dropdown
            size="sm"
            isOpen={optionsDropdownToggle}
            toggle={toggleOptions}
          >
            <DropdownToggle tag={NavLink} className="custom-link dark">
              <FontAwesomeIcon
                className="breadcrumb-wrapper-item"
                icon={faEllipsisH}
              />
            </DropdownToggle>
            <DropdownMenu
              modifiers={{
                setMaxHeight: {
                  enabled: true,
                  order: 890,
                  fn: data => {
                    return {
                      ...data,
                      styles: {
                        ...data.styles,
                        overflow: "auto",
                        maxHeight: "100px"
                      }
                    };
                  }
                }
              }}
              right
            >
              <DropdownItem onClick={() => setModifyTokensOption(true)}>
                Modify Paid Tokens
              </DropdownItem>
              <DropdownItem onClick={() => setFreeModifyTokensOption(true)}>
                Modify Free Tokens
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  props.removeAccess(
                    props.user,
                    numberOfTokens,
                    freeNumberOfTokens,
                    props.user.app_metadata.renterType
                  )
                }
              >
                Remove Access
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.user.app_metadata.renterType = "active-renter";
                  props.modifyRenter(props.user, "active-renter");
                }}
              >
                Make Active Renter
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.user.app_metadata.renterType = "occasional-renter";
                  props.modifyRenter(props.user, "occasional-renter");
                }}
              >
                Make Occasional Renter
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  props.user.app_metadata.renterType = 'prospective-renter'
                  props.modifyRenter(props.user, "prospective-renter");
                }}
              >
                Make Prospective Renter
              </DropdownItem>
              <DropdownItem />
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
};
export default UserManagementRow;