import React, { useContext } from "react";
import { TokenContext } from "../Context/TokenContext";
import { FreeTokensContext } from "../Context/FreeTokensContext";

const Counter = () => {
  const { tokens } = useContext(TokenContext);
  const { freeTokens } = useContext(FreeTokensContext);
  return (
    <>
      {tokens !== 0 && tokens && (
        <div className="card-title input-container">
          Paid Tokens Left: {tokens}
        </div>
      )}
      {freeTokens !== 0 && freeTokens && (
        <div className="card-title input-container">
          Free Tokens Left: {freeTokens}
        </div>
      )}
      {tokens === 0 && freeTokens === 0 && (
        <>
          <div
            className="card-title input-container"
            style={{ color: "#8e1b30" }}
          >
            You have run out of tokens. Please purchase more to generate additional charts.
          </div>
        </>
      )}
    </>
  );
};

export default Counter;