import { Slugify } from "./Slugify";

class MetaDataValues {
  constructor(
    uom
  ) {
    this.uom = uom;
  }
}

export const getUserMetadata = (user) => {
    return user && user["https://portal.sandtinel.com_user_metadata"];
}

export const getMetadataValues = (user, projectId, customerId) => {
    let metadata = getUserMetadata(user);

    if (!metadata || !metadata.preferences) return null;

    let customerIndex = metadata.preferences.customers.findIndex(c => c.id === customerId);
    if (customerIndex === -1) return null;

    let projectIndex = metadata.preferences.customers[
      customerIndex
    ].projects.findIndex(p => p.id === projectId);
    if (projectIndex === -1) return null;
    let userUOM =
      metadata.preferences.customers[customerIndex].projects[projectIndex].uom;

    return new MetaDataValues(userUOM);
}

export const newMetadataValues = (user, customer, project, projectId, customerId, UOM) => {
    let projectName = Slugify(project);
    let customerName = Slugify(customer);
    let metadata = getUserMetadata(user);
    if (!metadata || !metadata.preferences){
        return {
            preferences: {
                customers: [{
                    id: customerId,
                    name: customerName,
                    projects: [{
                        id: projectId,
                        name: projectName,
                        uom: UOM,
                    }]
                }]
            }
        }
    }

    let customerIndex = metadata.preferences.customers.findIndex(c => c.id === customerId);
    if (customerIndex === -1){
        metadata.preferences.customers.push({
            id: customerId,
            name: customerName,
            projects: [{
                id: projectId,
                name: projectName,
                uom: UOM,
            }]
        })
        return metadata;
    }
    
    let projectIndex = metadata.preferences.customers[customerIndex].projects.findIndex(p => p.id === projectId);
    if (projectIndex === -1){
        metadata.preferences.customers[customerIndex].projects.push({
            id: projectId,
            name: projectName,
            uom: UOM,
        })
        return metadata;
    }

    metadata.preferences.customers[customerIndex].projects[projectIndex].uom = UOM;
    return metadata;
}
