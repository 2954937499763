import React, { useContext, useState } from "react";
import { Button } from "reactstrap";
import { SavedChartContext } from "../../Context/SavedChartContext";
import Api from "../../Helpers/Api";
import { CreateCurve } from "../../Helpers/CreateCurve";
import "./Curves.css";
import { ActiveChartContext } from "../../Context/ActiveChartContext";
import { UserSettingsContext } from "../../Context/UserSettingsContext";

import {
  ConvertCelsiusToFahrenheit,
  ConvertGasFlowRateToImperial,
  ConvertGasPseudoCriticalPressureToImperial, ConvertGasPseudoCriticalTemperatureToImperial,
  ConvertKPaToPSI,
  ConvertLiquidFlowRateToImperial,
  ConvertSandSizeToImperial
} from "../../Helpers/UnitOfMeasurementConverter";
import { ConvertSandLock } from "../../Helpers/ConvertSandLock";
import { UserSavedChartSettingsContext } from "../../Context/UserSavedChartSettingsContext";
import { ParseRecommendation } from "../../Helpers/ParseRecommendation";
import { ConversionUtility } from "../Chart/ConversionUtility";

const CurveLine = props => {
  const api = new Api();
  const { setSavedChartData } = useContext(SavedChartContext);

  const { isMetric } = useContext(UserSettingsContext);
  const { activeChartGuid, setActiveChartGuid } = useContext(
    ActiveChartContext
  );
  const { setUserSavedChartSettings } = useContext(
    UserSavedChartSettingsContext
  );
  const curveInput = props.children.curveInput;
  const recommendation = ParseRecommendation({
    type: props.children.productType,
    sandLock: curveInput.sandLock,
    sphereSize: curveInput.sphereSize,
    class: props.children.pressureLimitsByClass
  });
  const [convertedValues] = useState(ConversionUtility(isMetric));

  let activeChart =
    activeChartGuid === props.children.recommendationGuid
      ? "pumperLine active-curve"
      : "pumperLine";

  const loadChart = async () => {
    setUserSavedChartSettings({
      temperature:
        (isMetric
          ? curveInput.temperature
          : ConvertCelsiusToFahrenheit(curveInput.temperature)) +
        ` ${convertedValues.temperature.units}`,
      pressure:
        (isMetric
          ? curveInput.pressure
          : ConvertKPaToPSI(curveInput.pressure).toFixed(2)) +
        ` ${convertedValues.pressure.units}`,
      gasFlowRate:
        (isMetric
          ? curveInput.gasFlowRate
          : ConvertGasFlowRateToImperial(curveInput.gasFlowRate).toFixed(2)) + ` ${convertedValues.gasFlowRate.units}`,
      liquidFlowRate:
        (isMetric
          ? curveInput.liquidFlowRate 
          : ConvertLiquidFlowRateToImperial(curveInput.liquidFlowRate)) + ` ${convertedValues.liquidFlowRate.units}`,
      waterCutPercentage: curveInput.waterCutPercentage + " %",
      sandSize:
        (isMetric
          ? curveInput.sandSize + " microns"
          : ConvertSandSizeToImperial(curveInput.sandSize) + ` ${convertedValues.sandSize.units}`) 
        ,
      gasMolecularWeight:
          (isMetric ? curveInput.gasMolecularWeight + " g/mol" : curveInput.gasMolecularWeight + " lb/lbmol"),
      gasPseudoCriticalPressure:
        (isMetric
          ? curveInput.gasPseudoCriticalPressure
          : ConvertGasPseudoCriticalPressureToImperial(
              curveInput.gasPseudoCriticalPressure
            ).toFixed(3)) + ` ${convertedValues.gasPseudoCriticalPressure.units}`,
      gasPseudoCriticalTemperature:
          (isMetric
              ? curveInput.gasPseudoCriticalTemperature
              : ConvertGasPseudoCriticalTemperatureToImperial(
                  curveInput.gasPseudoCriticalTemperature
              ).toFixed(1)) + ` ${convertedValues.gasPseudoCriticalTemperature.units}`,
      creationDate: props.children.createdDateString,
      recommendation: `${recommendation.inches}” ${recommendation.tradeName} ${recommendation.pressureClass} with ${recommendation.sandLock}`
    });
    await api
      .generateCurveByRecommendationGuid(
        props.children.recommendationGuid,
        props.token
      )
      .then(response => {
        if (response.status === 200) {
          setSavedChartData(
            CreateCurve(
              response.data,
              [curveInput.gasFlowRate, curveInput.liquidFlowRate],
              isMetric
            )
          );
          setActiveChartGuid(props.children.recommendationGuid);
          window.scrollTo(0, 0);
        }
      });
  };

  return (
    <>
      <tr>
        <td>
          <div className={activeChart}>
            <div className="curve-inner-container">
              <div className="curve-column">
                {recommendation.inches}” {recommendation.tradeName}{" "}
                {recommendation.pressureClass} with {recommendation.sandLock}
              </div>
              <div className="curve-column">
                <ul
                  style={{
                    "list-style-type": "none",
                    padding: 0,
                    marginBottom: "8px"
                  }}
                >
                  <li>
                    Temperature: {isMetric ? curveInput.temperature : ConvertCelsiusToFahrenheit(curveInput.temperature)} {convertedValues.temperature.units}
                  </li>
                  <li>
                    Pressure: {isMetric ? curveInput.pressure : ConvertKPaToPSI(curveInput.pressure).toFixed(2)} {convertedValues.pressure.units}
                  </li>
                  <li>
                    Gas Flow Rate: {isMetric ? curveInput.gasFlowRate : ConvertGasFlowRateToImperial(curveInput.gasFlowRate).toFixed(2)} {convertedValues.gasFlowRate.units}
                  </li>
                  <li>
                    Liquid Flow Rate: {isMetric ? curveInput.liquidFlowRate : ConvertLiquidFlowRateToImperial(curveInput.liquidFlowRate)} {convertedValues.liquidFlowRate.units}
                  </li>
                  <li>
                    Water Cut Percentage: {curveInput.waterCutPercentage} %
                  </li>
                  <li>Sand Lock: {ConvertSandLock(curveInput.sandLock)}</li>
                  <li>
                    Sand Size: {isMetric ? curveInput.sandSize : ConvertSandSizeToImperial(curveInput.sandSize)} {convertedValues.sandSize.units}
                  </li>
                  <li>Sphere Size: {curveInput.sphereSize} inches</li>
                  <li>
                    Gas Molecular Weight: {curveInput.gasMolecularWeight} {convertedValues.gasMolecularWeight.units}
                  </li>
                  <li>
                    Gas Pseudo Critical Pressure: {isMetric ? curveInput.gasPseudoCriticalPressure : ConvertGasPseudoCriticalPressureToImperial(curveInput.gasPseudoCriticalPressure).toFixed(3)} {convertedValues.gasPseudoCriticalPressure.units}
                  </li>
                  <li>
                    Gas Pseudo Critical Temperature: {isMetric ? curveInput.gasPseudoCriticalTemperature : ConvertGasPseudoCriticalTemperatureToImperial(curveInput.gasPseudoCriticalTemperature).toFixed(1)} {convertedValues.gasPseudoCriticalTemperature.units}
                  </li>
                </ul>
              </div>
              <div className="curve-column">
                {props.children.createdDateString}
              </div>
              <div className="curve-load-button">
                <Button
                  color="danger"
                  className="float-right"
                  outline
                  onClick={loadChart}
                  size="md"
                >
                  Load Chart
                </Button>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default CurveLine;
