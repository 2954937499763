import React, { useContext} from "react";
import "./Chart.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import { UserSettingsContext } from "../../Context/UserSettingsContext";
import {Bubble, Line, Scatter} from "react-chartjs-2";
import { MetricChartOptions, ImperialChartOptions } from "./ChartOptions";
import { Container} from "react-bootstrap";

const Chart = props => {
  const { isMetric } = useContext(UserSettingsContext);
  return (
      <>
        {props.chartData && (
            <Container className="wellStatsContainer" fluid={true}>
              <Scatter
                  data={props.chartData}
                  options={isMetric ? MetricChartOptions : ImperialChartOptions}
                  width={1000}
                  height={275}
              />
            </Container>
        )}
      </>
  );
};

export default Chart;
