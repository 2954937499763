import React, { useState, useEffect, useContext} from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import {Auth0Context} from "../../Context/Auth0Context";
import fetchWithTimeout from "../../Helpers/FetchWithTimeout";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

let tempClientID;

export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const appSettings = async () => {
    if (process.env.REACT_APP_LOCAL_CACHE_TIME !== undefined) {
      
      global.apiEndpoint = process.env.REACT_APP_API_URL;
      global.auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
      global.auth0ClientId = process.env.REACT_APP_AUTH0_CLIENTID;
      global.auth0RedirectUri = process.env.REACT_APP_AUTH0_REDIRECTURI;
      global.auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE;

      // Default values
      global.pressure = process.env.REACT_APP_PRESSURE_DEFAULT;
      global.temperature = process.env.REACT_APP_TEMPERATURE_DEFAULT;
      global.gasFlowRate = process.env.REACT_APP_GAS_FLOW_RATE_DEFAULT;
      global.liquidFlowRate = process.env.REACT_APP_LIQUID_FLOW_RATE_DEFAULT;
      global.waterCutPercentage = process.env.REACT_APP_WATER_CUT_PERCENTAGE_DEFAULT;
      global.sandSize = process.env.REACT_APP_SAND_SIZE_DEFAULT;
      global.gasPseudoCriticalPressure = process.env.REACT_APP_GAS_PSEUDO_CRITICAL_PRESSURE_DEFAULT;
      global.gasPseudoCriticalTemperature = process.env.REACT_APP_GAS_PSEUDO_CRITICAL_TEMPERATURE_DEFAULT;
      global.gasMolecularWeight = process.env.REACT_APP_GAS_MOLECULAR_WEIGHT_DEFAULT;
      global.activeRenterUSDPrice = process.env.REACT_APP_ACTIVE_RENTER_USD_PRICE;
      global.occasionalRenterUSDPrice = process.env.REACT_APP_OCCASIONAL_RENTER_USD_PRICE;
      global.prospectiveRenterUSDPrice = process.env.REACT_APP_PROSPECTIVE_RENTER_USD_PRICE;
      global.activeRenterCADPrice = process.env.REACT_APP_ACTIVE_RENTER_CAD_PRICE;
      global.occasionalRenterCADPrice = process.env.REACT_APP_OCCASIONAL_RENTER_CAD_PRICE;
      global.prospectiveRenterCADPrice = process.env.REACT_APP_PROSPECTIVE_RENTER_CAD_PRICE;

      return process.env.REACT_APP_AUTH0_CLIENTID;
    } 
    else {
        global.apiEndpoint = process.env.REACT_APP_API_URL;

        let myRequest = new Request(global.apiEndpoint + "settings/keys");

        await fetchWithTimeout(myRequest).then(function (response) {
          if (response.status === 200) {
            return response.json();
          }
        }).then(function (jsonResponse) {
          tempClientID = jsonResponse.auth0ClientID;
          
          global.auth0Domain = jsonResponse.auth0Domain;
          global.auth0RedirectUri = jsonResponse.auth0RedirectURI;
          global.auth0ClientId = jsonResponse.auth0ClientID;
          global.auth0Audience = jsonResponse.auth0Audience;
          global.pressure = jsonResponse.pressureDefault;
          global.temperature = jsonResponse.temperatureDefault;
          global.gasFlowRate = jsonResponse.gasFlowRateDefault;
          global.liquidFlowRate = jsonResponse.liquidFlowRateDefault;
          global.waterCutPercentage = jsonResponse.waterCutPercentageDefault;
          global.sandSize = jsonResponse.sandSizeDefault;
          global.gasPseudoCriticalPressure = jsonResponse.gasPseudoCriticalPressureDefault;
          global.gasPseudoCriticalTemperature = jsonResponse.gasPseudoCriticalTemperatureDefault;
          global.gasMolecularWeight = jsonResponse.gasMolecularWeightDefault;
          global.activeRenterUSDPrice = jsonResponse.activeRenterUsdPrice;
          global.occasionalRenterUSDPrice = jsonResponse.occasionalRenterUsdPrice;
          global.prospectiveRenterUSDPrice = jsonResponse.prospectiveRenterUsdPrice;
          global.activeRenterCADPrice = jsonResponse.activeRenterCadPrice;
          global.occasionalRenterCADPrice = jsonResponse.occasionalRenterCadPrice;
          global.prospectiveRenterCADPrice = jsonResponse.prospectiveRenterCadPrice;
        });
    }
  };

  useEffect(() => {
    appSettings().then(() => {
      initAuth0();
    });
  }, []);

  const initAuth0 = async () => {
    const initOptions = {
      domain: global.auth0Domain,
      client_id: global.auth0ClientId,
      redirect_uri: global.auth0RedirectUri,
      audience: global.auth0Audience,
      leeway: 300,
      allowSignUp: false
    };

    const auth0FromHook = await createAuth0Client(initOptions);

    setAuth0(auth0FromHook);

    if (window.location.search.includes("code=")) {
      const { appState } = await auth0FromHook.handleRedirectCallback();
      sessionStorage.clear();
      onRedirectCallback(appState);
    }

    const isAuthenticated = await auth0FromHook.isAuthenticated();

    setIsAuthenticated(isAuthenticated);

    if (isAuthenticated) {
      const user = await auth0FromHook.getUser();
      setUser(user);
    }

    setLoading(false);
  };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  return (
    <>
      <Auth0Context.Provider
        value={{
          isAuthenticated,
          user,
          loading,
          popupOpen,
          loginWithPopup,
          handleRedirectCallback,
          getUser: (...p) => auth0Client.getUser(...p),
          getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
          loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
          getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
          getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
          logout: (...p) => auth0Client.logout(...p),
        }}
      >
        {children}
      </Auth0Context.Provider>
    </>
  );
};