import {
  ConvertCelsiusToFahrenheit,
  ConvertGasFlowRateToImperial,
  ConvertGasPseudoCriticalPressureToImperial,
  ConvertGasPseudoCriticalTemperatureToImperial,
  ConvertKPaToPSI,
  ConvertLiquidFlowRateToImperial,
  ConvertSandSizeToImperial
} from "./UnitOfMeasurementConverter";

export const AppSettingsImperial = () => ({
  pressure: ConvertKPaToPSI(global.pressure).toFixed(2),
  temperature: ConvertCelsiusToFahrenheit(global.temperature).toFixed(2),
  gasFlowRate: ConvertGasFlowRateToImperial(global.gasFlowRate).toFixed(2),
  liquidFlowRate: ConvertLiquidFlowRateToImperial(global.liquidFlowRate),
  waterCutPercentage: global.waterCutPercentage,
  sandSize: ConvertSandSizeToImperial(global.sandSize),
  gasPseudoCriticalPressure: ConvertGasPseudoCriticalPressureToImperial(global.gasPseudoCriticalPressure).toFixed(3),
  gasPseudoCriticalTemperature: ConvertGasPseudoCriticalTemperatureToImperial(global.gasPseudoCriticalTemperature).toFixed(1),
  gasMolecularWeight: global.gasMolecularWeight
});
