import axios from "axios";
import { NetworkRequestStatus } from "../../Enums";

export interface UserPermissionResponse {
  hasAccess: boolean;
  status: NetworkRequestStatus;
  error: string;
}

export enum AccessType {
  IsSiteAdmin,
  IsCustomerAdmin,
  IsProjectAdmin,
  IsCustomerViewer,
  IsProjectViewer,
    IsSalesStaff,
    IsStaff
}

const isProjectViewer = async (
  endpoint: string,
  token: string,
  customerId: number,
  projectId: number
) =>
  await axios
    .get<UserPermissionResponse>(endpoint + "Auth/is-project-viewer", {
      params: {
        projectId: projectId,
        customerId: customerId
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(function(response) {
      return response.status === 401 || response.status === 403
        ? false
        : response.status !== 200
            ? false
            : response.data && response.data.status !== NetworkRequestStatus.Successful
                ? false
                : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                    ? response.data.hasAccess
                    : false;
    })
    .catch(e => false);

const isCustomerViewer = async (
  endpoint: string,
  token: string,
  customerId: number
) =>
  await axios
    .get<UserPermissionResponse>(endpoint + "Auth/is-customer-viewer", {
      params: {
        customerId: customerId
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(function(response) {
      return response.status === 401 || response.status === 403
        ? false
        : response.status !== 200
            ? false
            : response.data && response.data.status !== NetworkRequestStatus.Successful
                ? false
                : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                    ? response.data.hasAccess
                    : false;
    })
    .catch(e => false);

const isProjectAdmin = async (
  endpoint: string,

  token: string,
  customerId: number,
  projectId: number
) => {
  return await axios
    .get<UserPermissionResponse>(endpoint + "Auth/is-project-admin", {
      params: {
        projectId: projectId,
        customerId: customerId
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(function(response: any) {
      switch (response.status) {
        case 401:
        case 403:
          return false;
      }
      if (response.status !== 200) {
        return false;
      } else if (
        response.data && response.data.status !== NetworkRequestStatus.Successful
      ) {
        return false;
      } else if (
        response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
      ) {
        return response.data.hasAccess;
      }
    })
    .catch(e => false);
};

const isCustomerAdmin = async (
  endpoint: string,
  token: string,
  customerId: number
) =>
  await axios
    .get<UserPermissionResponse>(endpoint + "Auth/is-customer-admin", {
      params: {
        customerId: customerId
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(function(response) {
      return response.status === 401 || response.status === 403
        ? false
        : response.status !== 200
            ? false
            : response.data && response.data.status !== NetworkRequestStatus.Successful
                ? false
                : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                    ? response.data.hasAccess
                    : false;
    })
    .catch(e => false);

const isSiteAdmin = async (endpoint: string, token: string) =>
  await axios
    .get<UserPermissionResponse>(endpoint + "Auth/is-site-admin", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(function(response) {
      switch (response.status) {
        case 401:
        case 403:
          return false;
      }
      return response.status !== 200
        ? false
        : response.data && response.data.status !== NetworkRequestStatus.Successful
            ? false
            : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                ? response.data.hasAccess
                : false;
    })
    .catch(e => false);

const isSalesStaff = async (endpoint: string, token: string) =>
    await axios
        .get<UserPermissionResponse>(endpoint + "Auth/is-sales-staff", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function(response) {
            switch (response.status) {
                case 401:
                case 403:
                    return false;
            }
            return response.status !== 200
                ? false
                : response.data && response.data.status !== NetworkRequestStatus.Successful
                    ? false
                    : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                        ? response.data.hasAccess
                        : false;
        })
        .catch(e => false);


const isStaff = async (endpoint: string, token: string) =>
    await axios
        .get<UserPermissionResponse>(endpoint + "Auth/is-staff", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(function(response) {
            switch (response.status) {
                case 401:
                case 403:
                    return false;
            }
            return response.status !== 200
                ? false
                : response.data && response.data.status != NetworkRequestStatus.Successful
                    ? false
                    : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                        ? response.data.hasAccess
                        : false;
        })
        .catch(e => false);

export {isProjectAdmin, isCustomerAdmin, isSiteAdmin, isProjectViewer, isCustomerViewer, isSalesStaff, isStaff}