
const LookupTradeName = modelName => {
    switch (modelName) {
        case "G1":
            return "G1 Sandtinel";
        case "G2":
            return "Defender";
        case "G4":
            return "Maverick";
        default:
            return "No Recommendation";
    }
};

const LookupSandLock = sandLockCode => {
    switch (sandLockCode) {
        case "SL0":
            return "No Sand Lock";
        case "SL1":
            return "Sand Lock 1";
        case "SL2":
            return "Sand Lock 2";
        case "SL3":
            return "Sand Lock 3";
        default:
            return "No Sand Lock";
    }
};

const LookUpPressureClass = pressureClassCode => {
  switch (pressureClassCode) {
    case "One":
      return "CL0";
    case "Two":
      return "CL1440";
    case "Three":
      return "CL3700";
    case "Four":
      return "CL5000";
    case "Five":
      return "CL10000";
    default:
      return "No Pressure Class";
  }
};

export const ParseRecommendation = product => {
  return {
    tradeName: LookupTradeName(product.type[0] + "" + product.type[1]),
    sandLock: LookupSandLock(product.sandLock),
    inches: product.sphereSize,
    pressureClass: LookUpPressureClass(product.class)
  }
}