import React, {useEffect, useState} from "react";
import {validationSchema} from "./Validation";
import {Form, Formik} from "formik";
import FormikControl from "../Formik/FormikControl";
import {Button} from "reactstrap";
import "./Project.css";
import Api from "../../Helpers/Api";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Project = props => {
    const api = new Api();
    toast.configure();
    const [customerName, setCustomerName] = useState("");
    const [projectName, setProjectName] = useState("");
    const [legalSubDivision, setLegalSubDivision] = useState("");
    const [wellName, setWellName] = useState("");
    const [basin, setBasin] = useState("");
    const [latitude, setLatitude] = useState(0.0);
    const [longitude, setLongitude] = useState(0.0);
    const [country, setCountry] = useState("");
    const [
        dropDownOptionsForCustomers,
        setDropDownOptionsForCustomers
    ] = useState([]);
    const [dropDownOptionsForCountries] = useState([
        {key: "Select a Country", value: ""},
        {key: "USA", value: "USA"},
        {key: "Canada", value: "Canada"}
    ]);

    const saveProject = async project =>
        await api.saveProject(
            {
                projectName: project.projectName,
                legalSubDivision: project.legalSubDivision,
                wellName: project.wellName,
                basin: project.basin,
                latitude: project.latitude,
                longitude: project.longitude,
                country: project.country,
                customerId: dropDownOptionsForCustomers.find(
                    o => o.key === project.customerName
                ).Id
            },
            props.token,
            0,
            0
        );

    const getAllCustomers = async () =>
        await api.getAllCustomers(props.token).then(response => {
            if (response.status === 200) {
                const dropDownOptions = response.data.map(customer => ({
                    key: customer.customerName,
                    value: customer.customerName,
                    Id: customer.customerId
                }));
                dropDownOptions.unshift({key: "Select a Customer", value: ""});
                setDropDownOptionsForCustomers(dropDownOptions);
            }
        });

    useEffect(() => {
        getAllCustomers().then();
    }, []);

    const initialValues = {
        projectName,
        legalSubDivision,
        wellName,
        basin,
        latitude,
        longitude,
        customerName,
        country
    };

    const onSubmit = values => {
        setProjectName(values.projectName);
        setCustomerName(values.key);
        setLegalSubDivision(values.legalSubDivision);
        setWellName(values.wellName);
        setBasin(values.basin);
        setLatitude(values.latitude);
        setLongitude(values.longitude);
        setCustomerName(values.customerName);
        setCountry(values.country);
        saveProject(values)
          .then(response => {
            if (response.status === 200)
              toast.success("Project Created", {
                position: toast.POSITION.TOP_RIGHT,
                onClose: () => window.location.reload(true)
              });
            else if (response.status === 204) {
              toast.error(
                `Cannot add project with the name ${values.projectName} for this customer as it already exists`,
                {
                  position: toast.POSITION.TOP_RIGHT
                }
              );
            }
          })
          .catch(e => {
            toast.error(`Error: ${e}`);
          });
    };

    return (
        <div style={{ padding: "40px" }}>
            <div className="projectInfoTitle">Create a Project</div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                className="projectPreferences"
            >
                {formik => {
                    return (
                        <Form className="d-flex flex-column">
                            <FormikControl
                                control="input"
                                type="text"
                                label="Project Name"
                                name="projectName"
                                styles="chartName"
                                placeholder="Enter a Project Name"
                            />
                            <FormikControl
                                control="select"
                                label="Customer"
                                name="customerName"
                                options={dropDownOptionsForCustomers}
                            />
                            <div className="formik-group">
                                <div className="formik-title">Location:</div>
                                <div className="formik-controls">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Well Name"
                                        name="wellName"
                                        styles="chartName"
                                        placeholder="Enter a Well Name"
                                    />
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Basin"
                                        name="basin"
                                        styles="chartName"
                                        placeholder="Enter a Basin"
                                    />
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Legal Subdivision"
                                        name="legalSubDivision"
                                        styles="chartName"
                                        placeholder="Enter a Legal Subdivision"
                                    />
                                    <FormikControl
                                        control="select"
                                        label="Country"
                                        name="country"
                                        options={dropDownOptionsForCountries}
                                        styles="chartName"
                                    />
                                </div>
                            </div>
                            <div className="formik-group">
                                <div className="formik-title">GPS Coordinates:</div>
                                <div className="formik-controls">
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Latitude"
                                        name="latitude"
                                        styles="chartName"
                                        placeholder="Enter a latitude"
                                    />
                                    <FormikControl
                                        control="input"
                                        type="text"
                                        label="Longitude"
                                        name="longitude"
                                        styles="chartName"
                                        placeholder="Enter a longitude"
                                    />
                                </div>
                            </div>
                            <Button
                                color="danger"
                                className="float-right"
                                outline
                                type="submit"
                                size="md"
                                style={{marginBottom: "15px"}}
                                disabled={!formik.isValid}
                            >
                                Create Project
                            </Button>
                            <div className="formik-title" style={{"text-decoration": "none" }}>
                                This page will refresh after Project has been successfully created
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default Project;