import React, {useState, useEffect, useContext} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Table} from "reactstrap";
import {TokenContext} from "../../Context/TokenContext";

const SuccessfulTransaction = (props) => {
    const [session, setSession] = useState(null);
    const location = useLocation();
    const {tokens, setTokens} = useContext(TokenContext);

    const sessionId = location.search.replace('?session_id=', '');
    const formatUnitAmount = ({amount, currency}) => {
        const numberFormat = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
            currencyDisplay: 'symbol',
        });
        const parts = numberFormat.formatToParts(amount);
        let zeroDecimalCurrency = true;
        for (let part of parts) {
            if (part.type === 'decimal') {
                zeroDecimalCurrency = false;
            }
        }
        amount = zeroDecimalCurrency ? amount : amount / 100;
        return numberFormat.format(amount);
    }
    /* useEffect(() => {
         const fetchSession = async () => {
             await fetch(`${global.apiEndpoint}Stripe/checkout-session?sessionId=${sessionId}`, {
                 headers: {
                     'Content-Type': 'application/json',
                     Authorization: `Bearer ${props.token}`
                 },
             }).then((res) => {
                     return res.json()
                 }
             ).then((data) => {
                 setTokens(tokens + Number(data.metadata['tokensPurchased']))
                 setSession(data)
             })
         };
         fetchSession();
     }, [sessionId]);
     */
    useEffect(() => {
        if (!window.location.hash) {
            window.location += "#loaded";
        }
    }, [])

    return (
        <div className="jumbotron text-center" style={{backgroundColor: "#1F2227"}}>
            <>
                <h1 className="display-3">Thank You For Your Purchase!</h1>
                <p className="lead"><strong>We have sent a receipt to your email.</strong></p>
                <p>
                    Having trouble? Contact us at
                    <strong> <a>customer-support@sandtinel.com</a></strong>
                </p>
                <p className="lead">
                    <Link className="btn btn-danger btn-sm" role="button" to={"/"} style={{color: "#fff"}}> Continue
                        to homepage</Link>
                </p>
            </>
        </div>
    );
};

export default SuccessfulTransaction;