import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import Api from "../../Helpers/Api";
import { SelectedProjectContext } from "../../Context/SelectedProjectContext";
import { useAuth0 } from "../Auth/auth0-wrapper";
import { toast } from "react-toastify";

const NotFound = props => {
  const api = new Api();
  toast.configure();
  const { selectedProject } = useContext(SelectedProjectContext);
  const { user } = useAuth0();

  const handleClick = async () => {
    await api
      .emailCFDSupport(
        {
          customer: selectedProject.customerName,
          project: selectedProject.projectName,
          email: user.email
        },
        props.token
      )
      .then(response => {
        if (response.status === 200)
          toast.success(
            "We have received your request. A CFD Support Specialist will reach out to you shortly.",
            {
              position: toast.POSITION.TOP_RIGHT
            }
          );
        else if (response.status === 204)
          toast.error("Error: Failed to send email", {
            position: toast.POSITION.TOP_RIGHT
          });
      });
  };

  return (
    <>
      <div
        className="menuItem"
        style={{ paddingLeft: "0px", borderLeft: "0px", textAlign: "center" }}
      >
        Based on the Operating conditions provided no recommendations were
        generated{" "}
        <div className="d-flex flex-column">
          <Button
            color="danger"
            outline
            size="md"
            style={{ margin: "15px" }}
            onClick={() => {
              handleClick();
            }}
          >
            Contact CFD Support
          </Button>
        </div>
      </div>
    </>
  );
};

export default NotFound;