export const ConvertSandLock = sandLock => {
  switch (sandLock) {
    case "SL0":
      return "No Sand Lock";
    case "SL1":
      return "Sand Lock 1";
    case "SL2":
      return "Sand Lock 2";
    case "SL3":
      return "Sand Lock 3";
  }
};