import React, { Component } from "react";
import { ProjectCurvedLinesContext } from "../../Context/ProjectCurvedLinesContext";

export class ProjectCurvedLinesProvider extends Component {
    constructor(props){
        super(props);
        this.state = {
            lines: [],
            prevLength: 0,
            prevGuid: null,
            initCheck: false
        };
    }

    // Increment prevLength
    updatePrevLength = () => {
        this.setState({
            prevLength: this.prevLength + 1
        })
    }

    // Update single value in state
    updateCurvedLines = (key, value) => {
        this.setState({ [key]: value })
    }

    // Update all values in state
    setCurvedLines = (value) => {
        this.setState(value);
    }

    render() {
        return (
            <ProjectCurvedLinesContext.Provider
                value={{
                    projectCurvedLines: this.state,
                    setProjectCurvedLines: this.setCurvedLines,
                    updateProjectCurvedLines: this.updateCurvedLines,
                    updateProjectPrevLength: this.updatePrevLength
                }}
            >
                {this.props.children}
            </ProjectCurvedLinesContext.Provider>
        );
    }
}