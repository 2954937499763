import React, {useContext, useEffect, useState} from "react";
import {AccessType} from "../../Auth/accessControlService";
import {UserTypeContext} from "../../../Context/UserTypeContext";
import Loader from "../Loader/Loader";

const AccessControl = ({children, renderNoAccess, accessTypes}) => {
    const [permitted, setPermitted] = useState(false)
    const {userType} = useContext(UserTypeContext);
    const accessCheckForMultiples = accessTypes =>
        accessTypes.forEach(type => {
            if (accessCheck(type))
                setPermitted(true);
        });

    const accessCheck = accessType => {
        switch (accessType) {
            case AccessType.IsSiteAdmin:
                return userType === "SiteAdmin"
            case AccessType.IsSalesStaff:
                return userType === "SalesStaff"
            case AccessType.IsStaff:
                return userType === "Staff"
            case AccessType.IsCustomerAdmin:
                return userType === "CustomerAdmin"
            case AccessType.IsCustomerViewer:
                return userType === "CustomerViewer"
            case AccessType.IsProjectAdmin:
                return userType === "ProjectAdmin"
            case AccessType.IsProjectViewer:
                return userType === "ProjectViewer"
        }
    };

    useEffect(() => {
        const fetchPermissions = accessTypes =>
            accessCheckForMultiples(accessTypes);
        fetchPermissions(accessTypes);
    }, [userType]);

    if (userType === null) {
        return (
            <div className="center">
                <Loader/>
            </div>
        )
    } else if (permitted === true)
        return children;
    else
        return renderNoAccess();
};

AccessControl.defaultProps = {
    renderNoAccess: () => null
};

export default AccessControl;