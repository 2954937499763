import React, { useContext, useEffect, useState } from "react";
import Api from "../Helpers/Api";
import { Input, Button, Table, UncontrolledTooltip } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserManagementRow from "./Common/UserManagementRow";
import { PermissionsEnum } from "../Interfaces/User";
import { NetworkRequestStatus } from "../Enums";
import axios from "axios";
import _ from "lodash";
import { SelectedProjectContext } from "../Context/SelectedProjectContext";
import { ConvertCamelCaseToTitleCase } from "../Helpers/Slugify";
import {ClientIdContext} from "../Context/ClientIdContext";

const ManageUsersPage = props => {
  const { selectedProject } = useContext(SelectedProjectContext);
  const [projectId] = useState(selectedProject.projectId);
  const [customerId] = useState(selectedProject.customerId);
  const [customer] = useState(selectedProject.customerName);
  const [project] = useState(selectedProject.projectName);
  const { frontendClientId } = useContext(ClientIdContext);

  
  const [users, setUsers] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [projectViewUsers, setProjectViewUsers] = useState(null);

  const api = new Api();
  toast.configure();

  const isValidEmail = email => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  };
  const getUsers = async () => {
    await api
      .getUsers(global.apiEndpoint, props.token, customerId, projectId)
      .then(response => {
        if (response.status === 200) {
          setUsers(response.data.users);
          if (response.data.users !== null) {
            if (response.data.users.length > 0) {
              setProjectViewUsers(
                _.filter(response.data.users, {
                  app_metadata: {
                    access: {
                      customers: [
                        {
                          projects: [
                            {
                              name: project,
                              id: projectId,
                              permission: "view"
                            }
                          ]
                        }
                      ]
                    }
                  }
                })
              );
            }
          }
        }
      });
  };

  const addViewersToProject = async () => {
    let emails = emailAddress.split(",");
    if (_.some(emails, e => !isValidEmail(e.trim()))) {
      let invalidEmail = _.find(emails, e => !isValidEmail(e.trim()));
      toast.error(
        `${invalidEmail} is not valid. Please enter a valid Email Address.`,
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
      return;
    }

    for (let item of emails) {
      if (_.some(projectViewUsers, e => e.email === item)) {
        toast.error(`User with the Email Address ${item} already exists`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      // need to figure out if the user has any tokens left, free tokens left and the Rentertype

      // lookup if user is in another project

      let UserAccess = {
        customers: [
          {
            id: customerId,
            name: customer,
            projects: [
              {
                id: projectId,
                name: project,
                permission: PermissionsEnum.View
              }
            ],
            permission: PermissionsEnum.None
          }
        ]
      };
      //check if user exists in state
      if (_.some(users, e => e.email === item)) {
        let existingUser = _.find(users, e => e.email === item);

        //check if user has current energy Producer
        if (
          _.some(
            existingUser.app_metadata.access.customers,
            e => e.id === customerId
          )
        ) {
          //add to projects
          let currentCustomer = _.find(
            existingUser.app_metadata.access.customers,
            e => e.id === customerId
          );

          currentCustomer.projects.push({
            id: projectId,
            name: project,
            permission: PermissionsEnum.View
          });
          UserAccess = existingUser.app_metadata.access;
        }
      }

      let renterType = "prospective-renter";
      let freeTokensLeft = 0;
      let tokensLeft = 0;

      await api
        .getUserByEmail(
          global.apiEndpoint,
          props.token,
          customerId,
          projectId,
          encodeURIComponent(item)
        )
        .then(response => {
          if (response.status === 200) {
            if (
              response.data.users != null 
            ) {
              freeTokensLeft =
                response.data.users[0].app_metadata.freeTokensLeft;
              tokensLeft = response.data.users[0].app_metadata.tokensLeft;
              renterType = response.data.users[0].app_metadata.renterType;
            }
          }
        });

      let AppMetadata = {
        freeTokensLeft: freeTokensLeft,
        tokensLeft: tokensLeft,
        renterType: renterType,
        access: UserAccess
      };

      if (props.token) {
        await api
          .projectAdminAction(
            global.apiEndpoint,
            props.token,
            {
              email: item.trim(),
              app_metadata: AppMetadata
            },
            customer,
            project,
            customerId,
            projectId,
            false,
              '',
              frontendClientId
          )
          .then(response => {
            if (response.status === 200)
              toast.success(`Successfully added ${item}`, {
                position: toast.POSITION.TOP_RIGHT
              });
          });
      }
      getUsers().then();
      setEmailAddress("");
    }
  };

  useEffect(() => {
    if (users !== null) {
      if (users.length > 0) {
        setProjectViewUsers(
          _.filter(users, {
            app_metadata: {
              access: {
                customers: [
                  {
                    projects: [
                      {
                        permission: "view",
                        id: projectId
                      }
                    ]
                  }
                ]
              }
            }
          })
        );
      }
    }
  }, [users]);

  useEffect(() => {
    getUsers();
  }, []);

  const resendEmailInvite = email => {
    axios
      .post(
        global.apiEndpoint +
          `Users/resend-invite?customerId=${customerId}&projectId=${projectId}`,
        {
          customer: customer,
          project: project,
          email: email,
          frontendClientId: frontendClientId
        },
        { headers: { Authorization: `Bearer ${props.token}` } }
      )
      .then(response => {
        response.data.status === NetworkRequestStatus.Successful
          ? toast.success(
              `An Email invite has been successfully sent to ${email}!`,
              {
                position: toast.POSITION.TOP_RIGHT
              }
            )
          : toast.error(response.data.error, {
              position: toast.POSITION.TOP_RIGHT
            });
      })
      .catch(error => {
        if (error.response !== undefined) {
          toast.error(error.response.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
  };

  const removeProjectAccess = async (
    user,
    numberOfTokens,
    freeNumberOfTokens,
    renterType
  ) => {
    for (let i = 0; i < user.app_metadata.access.customers.length; i++) {
      if (user.app_metadata.access.customers[i].id === customerId) {
        for (
          let j = 0;
          j < user.app_metadata.access.customers[i].projects.length;
          j++
        ) {
          if (
            user.app_metadata.access.customers[i].projects[j].id === projectId
          ) {
            user.app_metadata.access.customers[i].projects.splice(j, 1);
            j--;
          }
        }
      }
    }
    user.app_metadata.freeTokensLeft = freeNumberOfTokens;
    user.app_metadata.tokensLeft = numberOfTokens;
    user.app_metadata.renterType = renterType;
    if (props.token) {
      await api
        .projectAdminAction(
          global.apiEndpoint,
          props.token,
          user,
          customer,
          project,
          customerId,
          projectId,
          true
        )
        .then(response => {
          if (response.status === 200) {
            toast.success(`Successfully removed ${user.email}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            getUsers();
            setEmailAddress("");
            projectViewUsers.splice(
              projectViewUsers.findIndex(u => u.email === user.email)
            );
          }
        });
    }
  };

  const modifyRenterType = async (user, renterType) => {
    if (props.token) {
      await api
        .projectAdminAction(
          global.apiEndpoint,
          props.token,
          user,
          customer,
          project,
          customerId,
          projectId,
          false,
          renterType
        )
        .then(response => {
          if (response.status === 200) {
            toast.success(
              `Successfully changed ${
                user.email
              } renter type to ${ConvertCamelCaseToTitleCase(renterType)}`,
              {
                position: toast.POSITION.TOP_RIGHT
              }
            );
            getUsers();
          }
        });
    }
  };

  const modifyTokens = async user => {
    if (props.token) {
      await api
        .projectAdminAction(
          global.apiEndpoint,
          props.token,
          user,
          customer,
          project,
          customerId,
          projectId,
          true,
          user.app_metadata.renterType
        )
        .then(response => {
          if (response.status === 200) {
            toast.success(`Tokens modified successfully for ${user.email}.`, {
              position: toast.POSITION.TOP_RIGHT
            });
            getUsers();
          }
        });
    }
  };

  return (
    <>
      <div className="projectInfoTitle">Manage Users</div>
      <div className="noLeftPadding noRightPadding projectMenu col-2" />
      <div className="d-flex flex-column">
        <UncontrolledTooltip placement="right" target="email-addresses">
          Use a <strong>,</strong> to enter multiple Email Addresses
        </UncontrolledTooltip>
        <Input
          type="textarea"
          className="input-dark"
          value={emailAddress}
          id="email-addresses"
          onChange={e => setEmailAddress(`${e.target.value}`)}
          onKeyUp={event => {
            if (event.key === "Enter" || event.keyCode === 13) {
              addViewersToProject().then();
            }
          }}
          placeholder="Enter Email Addresses separated by a ','"
        />

        <Button
          color="danger"
          className="float-right"
          outline
          onClick={addViewersToProject}
          size="md"
          style={{ marginBottom: "15px" }}
        >
          Send Project Invites
        </Button>
        {users && (
          <>
            {projectViewUsers && (
              <>
                <Table dark responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Renter Type</th>
                      <th>Verification</th>
                      <th>Paid Tokens</th>
                      <th>Free Tokens</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {projectViewUsers.map(u => (
                      <>
                        <UserManagementRow
                          key={u.email}
                          user={u}
                          isAdmin={false}
                          emailInvite={resendEmailInvite}
                          customerName={customer}
                          projectName={project}
                          enableElevationToAdmin={true}
                          removeAccess={removeProjectAccess}
                          enableElevationToCustomer={true}
                          optionalClassName={"project-viewer"}
                          modifyRenter={modifyRenterType}
                          modifyTokens={modifyTokens}
                        />
                      </>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  </tfoot>
                </Table>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ManageUsersPage;

