import React from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import '../Project/Project.css';

function Input(props) {
  const { placeholder, styles, unit, label, name, ...rest } = props;
  return (
    <div className="formik-cell">
      <div className="projectInfoValue">
          <div className="projectInfoDataLabel">{label}</div>
          <Field
              id={name}
              name={name}
              {...rest}
              className={styles}
              placeholder={placeholder}
          />
        <ErrorMessage component={TextError} name={name} />
      </div>
    </div>
  );
}

export default Input