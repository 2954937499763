import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import Api from "../Helpers/Api";
import Chart from "./Chart/Chart";
import "./Curves/Curves.css";
import { SelectedProjectContext } from "../Context/SelectedProjectContext";
import CurveLineWithUser from "./Curves/CurveLineWithUser";
import { ProjectSavedChartContext } from "../Context/ProjectSavedChartContext";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SmallLogo from "../images/SmallLogo.png";
import autoTable from "jspdf-autotable";
import { ProjectSavedChartSettingsContext } from "../Context/ProjectSavedChartSettingsContext";
import { UserSettingsContext } from "../Context/UserSettingsContext";
import { ProjectCurvedLinesContext } from "../Context/ProjectCurvedLinesContext";
import Pagination from "../Components/Common/Pagination/Pagination";

const ProjectCurvesPage = props => {
  const api = new Api();
  const { savedProjectChartData } = useContext(ProjectSavedChartContext);
  const { isMetric } = useContext(UserSettingsContext);
  const { selectedProject } = useContext(SelectedProjectContext);
  const { projectCurvedLines, setProjectCurvedLines, updateProjectCurvedLines } = useContext(ProjectCurvedLinesContext);

  const requestChartPage = async () => {
    props.onBackClick();
  };

  const { projectSavedChartSettings } = useContext(
    ProjectSavedChartSettingsContext
  );

  const getProjectDataByProjectId = async () =>
    await api
      .getProjectByProjectId(selectedProject.projectId, props.token)
      .then(response => {
        if (response.status === 200) {
          const tempCurvedLines = response.data.recommendedProducts.map(
            (recommendation) => {
              return (
                <CurveLineWithUser
                  onBackClick={requestChartPage}
                  token={props.token}
                >
                  {recommendation}
                </CurveLineWithUser>
              );
            }
          );
          setProjectCurvedLines({lines: tempCurvedLines,
                                 prevLength: tempCurvedLines.length});
        }
      })
      .catch(e => {
        setProjectCurvedLines({lines: [],
                          prevLength: 0});
      });

  useEffect(() => {
    if (!projectCurvedLines.initCheck) {
      getProjectDataByProjectId(selectedProject.projectId).then();
      updateProjectCurvedLines("initCheck", true);
      updateProjectCurvedLines("prevGuid", `${selectedProject.customerId}-${selectedProject.projectId}`)
    } else if (projectCurvedLines.prevGuid !== `${selectedProject.customerId}-${selectedProject.projectId}`) {
      getProjectDataByProjectId(selectedProject.projectId).then();
      updateProjectCurvedLines("prevGuid", `${selectedProject.customerId}-${selectedProject.projectId}`)
    }
    else if (projectCurvedLines.lines.length !== projectCurvedLines.prevLength) {
      getProjectDataByProjectId(selectedProject.projectId).then();
    }
  }, [selectedProject.projectId]);

  const printPdfReport = () => {
    window.scrollTo(0, 0);
    const input = document.getElementById("printThis");
    html2canvas(input).then(canvas => {
      const gasFlowRateUnits = isMetric ? "e\xB3Sm\xB3/d" : "MMSCFD";
      const liquidFlowRateUnits = isMetric ? "m\xB3/d" : "BBL/d";

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape");
      // Page 1
      pdf.addImage(SmallLogo, "PNG", 5, 5);

      pdf.addImage(imgData, "PNG", 10, 20, 275, 75);

      autoTable(pdf, {
        startY: 100,
        body: [
          ["Recommendation", projectSavedChartSettings.recommendation],
          ["Curve Creation Date (UTC)", projectSavedChartSettings.creationDate],
          ["Curve Created By", projectSavedChartSettings.createdBy],
          ["Pressure", projectSavedChartSettings.pressure],
          ["Temperature", projectSavedChartSettings.temperature],
          ["Gas Flow Rate", projectSavedChartSettings.gasFlowRate],
          ["Liquid Flow Rate", projectSavedChartSettings.liquidFlowRate],
          [
            "Water Cut Percentage",
            projectSavedChartSettings.waterCutPercentage
          ],
          ["Sand Size", projectSavedChartSettings.sandSize],
          [
            "Gas Pseudo Critical Pressure",
            projectSavedChartSettings.gasPseudoCriticalPressure
          ],
          [
            "Gas Pseudo Critical Temperature",
            projectSavedChartSettings.gasPseudoCriticalTemperature
          ],
          ["Gas Molecular Weight", projectSavedChartSettings.gasMolecularWeight]
        ]
      });

      pdf.addPage();
      const formattedData = savedProjectChartData.datasets[0].data
        .slice(0, 34)
        .map(p => {
          return [
            `${p.x} ${gasFlowRateUnits}`,
            `${p.y} ${liquidFlowRateUnits}`
          ];
        });

      pdf.text("SANDTINEL OPERATING CURVE TABLE", 15, 10);

      autoTable(pdf, {
        startY: 15,
        head: [
          [`Gas (${gasFlowRateUnits})`, `Liquid (${liquidFlowRateUnits})`]
        ],
        body: formattedData
      });

      pdf.save("curve-report.pdf");
    });
  };

  return (
    <Row>
      <div className="projectInfoTitle">Project Saved Charts</div>
      <Col sm="12">
        {savedProjectChartData && (
          <>
            <div id="printThis">
              <Chart chartData={savedProjectChartData} />
            </div>
            <Button
              color="danger"
              outline
              className="col-12 col-lg-12"
              onClick={printPdfReport}
              size="md"
              style={{ marginBottom: "15px" }}
            >
              Generate PDF Report
            </Button>
          </>
        )}
        {projectCurvedLines.lines.length > 0 && (
          <>
            <div className="blurbText">
              These are all the charts that have been generated for this
              project. In this Menu you can go through all generated charts in
              this project.
            </div>
            <Pagination curvedLines={projectCurvedLines.lines} userCharts={false} />
          </>
        )}
        {projectCurvedLines.lines.length === 0 && (
            <>
              <div className="blurbText">
                No Charts have been generated for this Project. Generate charts to see them here.
              </div>
            </>
        )}
      </Col>
    </Row>
  );
};

export default ProjectCurvesPage