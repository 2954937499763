import React, { useContext, useEffect, useState } from "react";
import "tui-chart/dist/tui-chart.css";
import "./Chart/Chart.css";
import MainMenu from "./MainMenu";
import { Col, Container, Row } from "reactstrap";
import UserCurvesPage from "./UserCurvesPage";
import { AppSettingsMetric } from "../Helpers/AppSettingsMetric";
import { InputContext } from "../Context/InputContext";
import { ChartContext } from "../Context/ChartContext";
import ChartPage from "./ChartPage";
import { ProductContext } from "../Context/ProductContext";
import { ErrorContext } from "../Context/ErrorContext";
import { SavedChartContext } from "../Context/SavedChartContext";
import { ProjectSavedChartContext } from "../Context/ProjectSavedChartContext";
import { UserDataContext } from "../Context/UserDataContext";
import { ProjectDataContext } from "../Context/ProjectDataContext";
import { ActiveChartContext } from "../Context/ActiveChartContext";
import ConfigPage from "./ConfigPage";
import { UserSettingsContext } from "../Context/UserSettingsContext";
import { TokenContext } from "../Context/TokenContext";
import CustomerPage from "./CustomerPage";
import ProjectPage from "./ProjectPage";
import ProjectCurvesPage from "./ProjectCurvesPage";
import { ActiveProjectChartContext } from "../Context/ActiveProjectContext";
import { useAuth0 } from "./Auth/auth0-wrapper";
import { SelectedProjectContext } from "../Context/SelectedProjectContext";
import { getMetadataValues } from "../Helpers/MetadataHelper";
import { UserTypeContext } from "../Context/UserTypeContext";
import ManageUsersPage from "../Components/ManageUsersPage";
import PaymentsPage from "./PaymentsPage";
import { UserSavedChartSettingsContext } from "../Context/UserSavedChartSettingsContext";
import { ProjectSavedChartSettingsContext } from "../Context/ProjectSavedChartSettingsContext";
import { CurvedLinesProvider } from "./Providers/CurvedLinesProvider";
import { ProjectCurvedLinesProvider } from "./Providers/ProjectCurvedLinesProvider";
import { FreeTokensContext } from "../Context/FreeTokensContext";
import { AppSettingsImperial } from "../Helpers/AppSettingsImperial";

const MainPage = props => {
  const [input, setInput] = useState(AppSettingsMetric());
  const { selectedProject } = useContext(SelectedProjectContext);
  const [displayData, setDisplayData] = useState(null);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [savedChartData, setSavedChartData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [projectData, setProjectData] = useState(null);
  const [activeChartGuid, setActiveChartGuid] = useState(null);
  const [userSavedChartSettings, setUserSavedChartSettings] = useState(null);
  const [projectSavedChartSettings, setProjectSavedChartSettings] = useState(
    null
  );
  const [activeProjectChartGuid, setActiveProjectChartGuid] = useState(null);
  const [savedProjectChartData, setSavedProjectChartData] = useState(null);
  const [token] = useState(props.token);
  const { user } = useAuth0();
  const [isMetric, setIsMetric] = useState(null);
  const { userType } = useContext(UserTypeContext);
  const { setTokens } = useContext(TokenContext);
  const [freeTokens, setFreeTokens] = useState(0);
  const [showChartPage, setShowChartPage] = useState(true);
  const [showSavedChartsPage, setShowSavedChartsPage] = useState(false);
  const [showProjectInfo, setShowProjectInfo] = useState(false);
  const [showConfigPage, setShowConfigPage] = useState(false);
  const [showCustomerPage, setShowCustomerPage] = useState(false);
  const [showProjectPage, setShowProjectPage] = useState(false);
  const [showProjectChartPage, setShowProjectChartPage] = useState(false);
  const [showManageUsersPage, setShowManageUsersPage] = useState(false);
  const [showPaymentsPage, setShowPaymentsPage] = useState(false);
  const [appSettingsComplete, setAppSettingsComplete] = useState(false);
  const [statsBackgroundClass, setStatsBackgroundClass] = useState(
    "statsBackground"
  );

  const requestChartPage = () => {
    setShowChartPage(true);
    setShowSavedChartsPage(false);
    setShowProjectInfo(false);
    setShowConfigPage(false);
    setShowCustomerPage(false);
    setShowProjectPage(false);
    setShowProjectChartPage(false);
    setShowManageUsersPage(false);
    setShowPaymentsPage(false);
  };

  const requestManageUsersPage = () => {
    setShowChartPage(false);
    setShowSavedChartsPage(false);
    setShowConfigPage(false);
    setShowCustomerPage(false);
    setShowProjectPage(false);
    setShowProjectChartPage(false);
    setShowManageUsersPage(true);
    setShowPaymentsPage(false);
  };

  const requestSavedChartsPage = () => {
    setShowChartPage(false);
    setShowSavedChartsPage(true);
    setShowProjectInfo(false);
    setShowConfigPage(false);
    setShowCustomerPage(false);
    setShowProjectPage(false);
    setShowProjectChartPage(false);
    setShowManageUsersPage(false);
    setShowPaymentsPage(false);
  };

  const requestProjectInfo = () => {
    setShowChartPage(false);
    setShowSavedChartsPage(false);
    setShowConfigPage(true);
    setShowCustomerPage(false);
    setShowProjectPage(false);
    setShowProjectChartPage(false);
    setShowManageUsersPage(false);
    setShowPaymentsPage(false);
  };

  const requestProjectConfig = () => {
    setShowChartPage(false);
    setShowSavedChartsPage(false);
    setShowConfigPage(true);
    setShowCustomerPage(false);
    setShowProjectPage(false);
    setShowProjectChartPage(false);
    setShowManageUsersPage(false);
    setShowPaymentsPage(false);
  };

  const requestCustomerPage = () => {
    setShowChartPage(false);
    setShowSavedChartsPage(false);
    setShowConfigPage(false);
    setShowCustomerPage(true);
    setShowProjectPage(false);
    setShowProjectChartPage(false);
    setShowManageUsersPage(false);
    setShowPaymentsPage(false);
  };

  const requestPaymentsPage = () => {
    setShowChartPage(false);
    setShowSavedChartsPage(false);
    setShowConfigPage(false);
    setShowCustomerPage(false);
    setShowProjectPage(false);
    setShowProjectChartPage(false);
    setShowManageUsersPage(false);
    setShowPaymentsPage(true);
  };

  const requestProjectPage = () => {
    setShowChartPage(false);
    setShowSavedChartsPage(false);
    setShowConfigPage(false);
    setShowCustomerPage(false);
    setShowProjectPage(true);
    setShowProjectChartPage(false);
    setShowManageUsersPage(false);
    setShowPaymentsPage(false);
  };

  const requestProjectChartPage = () => {
    setShowChartPage(false);
    setShowSavedChartsPage(false);
    setShowConfigPage(false);
    setShowCustomerPage(false);
    setShowProjectPage(false);
    setShowProjectChartPage(true);
    setShowManageUsersPage(false);
    setShowPaymentsPage(false);
  };

  useEffect(() => {
    if (
      user["https://portal.sandtinel.com_app_metadata"] === undefined ||
      user["https://portal.sandtinel.com_app_metadata"] === null
    ) {
      setTokens(0);
      setFreeTokens(0);
    } else {
      setTokens(user["https://portal.sandtinel.com_app_metadata"].tokensLeft);
      setFreeTokens(
        user["https://portal.sandtinel.com_app_metadata"].freeTokensLeft
      );
    }
    setAppSettingsComplete(true);
  }, []);

  useEffect(() => {
    if (props.token) {

      let metadataValues = getMetadataValues(
        user,
        selectedProject.projectId,
        selectedProject.customerId
      );
      if (
        metadataValues !== null &&
        metadataValues !== undefined &&
        metadataValues.uom !== null
      ) {
        setIsMetric(metadataValues.uom == 2);
        if (metadataValues.uom == 1) setInput(AppSettingsImperial());
      } else {
        setIsMetric(true);
        setInput(AppSettingsMetric());
      }
      
      setDisplayData(null);
      setSavedChartData(null);
      setSavedProjectChartData(null);
      setActiveChartGuid("");
      setActiveProjectChartGuid("");
      setProduct(null);
      setError("none"); 
      requestChartPage();
    }
  }, [selectedProject.projectId]);

  return (
      <FreeTokensContext.Provider
        value={{ freeTokens: freeTokens, setFreeTokens: setFreeTokens }}
      >
        <UserSettingsContext.Provider value={{ isMetric, setIsMetric }}>
          <ProjectDataContext.Provider value={{ projectData, setProjectData }}>
            <UserDataContext.Provider value={{ userData, setUserData }}>
              <ProjectSavedChartContext.Provider
                value={{ savedProjectChartData, setSavedProjectChartData }}
              >
                <SavedChartContext.Provider
                  value={{ savedChartData, setSavedChartData }}
                >
                  <ProjectCurvedLinesProvider>
                    <CurvedLinesProvider>
                      <ProductContext.Provider value={{ product, setProduct }}>
                        <InputContext.Provider value={{ input, setInput }}>
                          <ChartContext.Provider
                            value={{ displayData, setDisplayData }}
                          >
                            <ErrorContext.Provider value={{ error, setError }}>
                              <Container fluid={true}>
                                <Row className="mainBackground">
                                  <Container fluid={true}>
                                    <Row>
                                      <Col
                                        xs="2"
                                        className="noRightPadding noLeftPadding projectMenu"
                                      >
                                        {token && (
                                          <MainMenu
                                            onChartClick={requestChartPage}
                                            onSavedChartsClick={
                                              requestSavedChartsPage
                                            }
                                            onProjectInfoClick={
                                              requestProjectInfo
                                            }
                                            onProjectConfigClick={
                                              requestProjectConfig
                                            }
                                            onCustomerPageClick={
                                              requestCustomerPage
                                            }
                                            onProjectPageClick={
                                              requestProjectPage
                                            }
                                            onProjectChartPageClick={
                                              requestProjectChartPage
                                            }
                                            onPaymentsPageClick={
                                              requestPaymentsPage
                                            }
                                            onUsersPageClick={
                                              requestManageUsersPage
                                            }
                                            token={token}
                                          />
                                        )}
                                      </Col>
                                      <Col
                                        xs="12"
                                        lg="9"
                                        className={statsBackgroundClass}
                                      >
                                        {(showChartPage === "true" ||
                                          showChartPage === true) && (
                                          <Col className={"padding-top-lg"}>
                                            {(appSettingsComplete === "true" ||
                                              appSettingsComplete === true) && (
                                              <ChartPage token={props.token} />
                                            )}
                                          </Col>
                                        )}
                                        <UserSavedChartSettingsContext.Provider
                                          value={{
                                            userSavedChartSettings,
                                            setUserSavedChartSettings
                                          }}
                                        >
                                          <ActiveChartContext.Provider
                                            value={{
                                              activeChartGuid,
                                              setActiveChartGuid
                                            }}
                                          >
                                            {(showSavedChartsPage === "true" ||
                                              showSavedChartsPage === true) && (
                                              <Col
                                                xs="12"
                                                className={"padding-top-lg"}
                                              >
                                                <UserCurvesPage
                                                  token={props.token}
                                                />
                                              </Col>
                                            )}
                                          </ActiveChartContext.Provider>
                                        </UserSavedChartSettingsContext.Provider>
                                        <ProjectSavedChartSettingsContext.Provider
                                          value={{
                                            projectSavedChartSettings,
                                            setProjectSavedChartSettings
                                          }}
                                        >
                                          <ActiveProjectChartContext.Provider
                                            value={{
                                              activeProjectChartGuid,
                                              setActiveProjectChartGuid
                                            }}
                                          >
                                            {(showProjectChartPage === "true" ||
                                              showProjectChartPage ===
                                                true) && (
                                              <Col
                                                xs="12"
                                                className={"padding-top-lg"}
                                              >
                                                <ProjectCurvesPage
                                                  token={props.token}
                                                />
                                              </Col>
                                            )}
                                          </ActiveProjectChartContext.Provider>
                                        </ProjectSavedChartSettingsContext.Provider>
                                        {(showCustomerPage === "true" ||
                                          showCustomerPage === true) && (
                                          <Col className={"padding-top-lg"}>
                                            <CustomerPage token={props.token} />
                                          </Col>
                                        )}

                                        {(showProjectPage === "true" ||
                                          showProjectPage === true) && (
                                          <Col
                                            xs="12"
                                            className={"padding-top-lg"}
                                          >
                                            <ProjectPage token={props.token} />
                                          </Col>
                                        )}

                                        {(showManageUsersPage === "true" ||
                                          showManageUsersPage === true) && (
                                          <Col
                                            xs="12"
                                            className={"padding-top-lg"}
                                          >
                                            <ManageUsersPage
                                              token={props.token}
                                            />
                                          </Col>
                                        )}

                                        {(showPaymentsPage === "true" ||
                                          showPaymentsPage === true) && (
                                          <Col
                                            xs="12"
                                            className={"padding-top-lg"}
                                          >
                                            <PaymentsPage token={props.token} />
                                          </Col>
                                        )}

                                        {(showConfigPage === "true" ||
                                          showConfigPage === true) && (
                                          <Col
                                            xs="12"
                                            lg="9"
                                            className={"padding-top-lg"}
                                          >
                                            {token && (
                                              <ConfigPage token={token} />
                                            )}
                                          </Col>
                                        )}
                                      </Col>
                                    </Row>
                                  </Container>
                                </Row>
                              </Container>
                            </ErrorContext.Provider>
                          </ChartContext.Provider>
                        </InputContext.Provider>
                      </ProductContext.Provider>
                    </CurvedLinesProvider>
                  </ProjectCurvedLinesProvider>
                </SavedChartContext.Provider>
              </ProjectSavedChartContext.Provider>
            </UserDataContext.Provider>
          </ProjectDataContext.Provider>
        </UserSettingsContext.Provider>
      </FreeTokensContext.Provider>
  );
};

export default MainPage