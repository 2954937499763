import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faSignOutAlt, faUsers, faHardHat } from "@fortawesome/free-solid-svg-icons";
import {
    ButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from "reactstrap";
import AccessControl from "../AccessControl/AccessControl";
import { AccessType } from "../../Auth/accessControlService";
import { UserTypeContext } from "../../../Context/UserTypeContext";
import { Link } from "react-router-dom";

export const ProfileDropdown = props => {
    const {userType} = useContext(UserTypeContext);
    return (
        <>
            <ButtonDropdown
                className="custom-link "
                tag={props.tag}
                to={props.to}
                isOpen={props.isOpen}
                toggle={props.toggle}
            >
                <DropdownToggle
                    tag={props.tag}
                    to={props.to}
                    size="lg"
                    color="secondary"
                >
                    <FontAwesomeIcon icon={faUserCircle} size="2x" color="#8e1b30"/>
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem header>Hi, {props.username}!</DropdownItem>
                    {userType && (
                        <AccessControl
                            accessTypes={[AccessType.IsSalesStaff, AccessType.IsSiteAdmin]}
                            renderNoAccess={() => ""}
                        >
                            <Link to={"/create-customer"}>
                                <DropdownItem>
                                    <span>
                                        Create a Customer &nbsp;
                                        <FontAwesomeIcon icon={faUsers}/>
                                    </span>
                                </DropdownItem>
                            </Link>
                        </AccessControl>
                    )}
                    {userType && (
                        <AccessControl
                            accessTypes={[AccessType.IsSalesStaff, AccessType.IsSiteAdmin]}
                            renderNoAccess={() => ""}
                        >
                            <Link to={"/create-project"}>
                                <DropdownItem>
                                    <span>
                                         Create a Project &nbsp;
                                         <FontAwesomeIcon icon={faHardHat}/>
                                    </span>
                                </DropdownItem>
                            </Link>
                        </AccessControl>
                    )}
                    <DropdownItem
                        onClick={() => props.logout({returnTo: global.auth0RedirectUri})}
                    >
                        <span>
                          Logout &nbsp;
                            <FontAwesomeIcon icon={faSignOutAlt}/>
                        </span>
                    </DropdownItem>

                </DropdownMenu>
            </ButtonDropdown>
        </>
    );
};
;