import * as Yup from 'yup'

export const validationSchema = Yup.object({
    projectName: Yup.string().required('Required').min(3).max(50),
    customerName: Yup.string().required('Required'),
    legalSubDivision: Yup.string().required('Required').min(3).max(50),
    wellName: Yup.string().required('Required').min(3).max(50),
    basin: Yup.string().required('Required').min(3).max(50),
    latitude: Yup.number().min(-90).max(90),
    longitude: Yup.number().min(-180).max(180),
    country: Yup.string().required('Required'),
})
