import React from 'react';
import {Link} from 'react-router-dom';

const CanceledTransaction = () => {
    return (
        <div className="jumbotron text-center" style={{backgroundColor: "#1F2227"}}>
            <h1 className="display-3">Your payment was canceled.</h1>
            <>
                <p>
                    Having trouble? Contact us at
                    <strong> <a>support@sandtinel.com</a></strong>
                </p>
                <p className="lead">
                    <Link className="btn btn-danger btn-sm" role="button" to={"/"}> Continue
                        to homepage</Link>
                </p>
            </>
        </div>
    );
};

export default CanceledTransaction;