import React from 'react';
import {Field, ErrorMessage} from 'formik';
import TextError from './TextError';
import '../Project/Project.css';

function Select(props) {
    const {unit, styles, label, name, options, ...rest} = props
    return (
        <div className="formik-cell">
            <div className="projectInfoValue">
                <div className="projectInfoDataLabel">{label}</div>
                <Field as='select' id={name} name={name} {...rest} className={styles}>
                    {options.map(option => {
                        return (
                            <option key={option.value} value={option.value}>
                                {option.key}
                            </option>
                        )
                    })}
                </Field>
                <ErrorMessage component={TextError} name={name}/>
            </div>
        </div>
    )
}


export default Select