import {
  ConvertCelsiusToFahrenheit,
  ConvertGasFlowRateToImperial,
  ConvertGasPseudoCriticalPressureToImperial,
  ConvertGasPseudoCriticalTemperatureToImperial,
  ConvertKPaToPSI,
  ConvertLiquidFlowRateToImperial, ConvertSandSizeToImperial
} from "../../Helpers/UnitOfMeasurementConverter";
import React from "react";

export const ConversionUtility = isMetric => ({
  pressure: {
    min: isMetric ? 1000 : ConvertKPaToPSI(1000),
    max: isMetric ? 30000 : ConvertKPaToPSI(30000),
    step: isMetric ? 100 : 1,
    units: isMetric ? "KPa" : "PSI",
    initialValue: isMetric ? global.pressure: ConvertKPaToPSI(global.pressure)
  },
  temperature: {
    min: isMetric ? 5 : ConvertCelsiusToFahrenheit(5),
    max: isMetric ? 60 : ConvertCelsiusToFahrenheit(60),
    step: 1,
    units: isMetric ? "Celsius (°C)" : "Fahrenheit (°F)",
    initialValue: isMetric ? global.temperature: ConvertCelsiusToFahrenheit(global.temperature)
  },
  gasFlowRate: {
    min: 0,
    max: isMetric ? 1000 : ConvertGasFlowRateToImperial(1000),
    step: isMetric ? 10 : 0.01,
    units: isMetric ? "e\xB3Sm\xB3/d" : "MMSCFD",
    initialValue: isMetric ? global.gasFlowRate: ConvertGasFlowRateToImperial(global.gasFlowRate)
  },
  liquidFlowRate: {
    min: 0,
    max: isMetric ? 1500 : ConvertLiquidFlowRateToImperial(1500),
    step: 10,
    units: isMetric ? "m\xB3/d" : "BBL/d",
    initialValue: isMetric ? global.liquidFlowRate: ConvertLiquidFlowRateToImperial(global.liquidFlowRate)
  },
  waterCutPercentage: {
    min: 0,
    max: 100,
    step: 1,
    units: "Percent (%)",
    initialValue: global.waterCutPercentage
  },
  sandSize: {
    min: 100,
    max: 300,
    step: 1,
    units: isMetric ? "microns (μm)" : "US Mesh Size",
    initialValue: isMetric ? Number(global.sandSize) : ConvertSandSizeToImperial(Number(global.sandSize))
  },
  gasMolecularWeight: {
    min: 16,
    max: 24,
    step: 0.1,
    units: isMetric ? "g/mol" : "lb/lb mol",
    initialValue: global.gasMolecularWeight
  },
  gasPseudoCriticalPressure: {
    min: isMetric ? 40 : ConvertGasPseudoCriticalPressureToImperial(40),
    max: isMetric ? 70 : ConvertGasPseudoCriticalPressureToImperial(70),
    step: 0.0001,
    units: isMetric ? "kgf/cm²" : "PSI",
    initialValue: isMetric ? global.gasPseudoCriticalPressure : ConvertGasPseudoCriticalPressureToImperial(global.gasPseudoCriticalPressure)
  },
  gasPseudoCriticalTemperature: {
    min: isMetric ? 200 : ConvertGasPseudoCriticalTemperatureToImperial(200),
    max: isMetric ? 260 : ConvertGasPseudoCriticalTemperatureToImperial(260),
    step: 0.001,
    units: isMetric ? "Kelvin (K)" : "Rankine (R)",
    initialValue: isMetric ? global.gasPseudoCriticalTemperature : ConvertGasPseudoCriticalTemperatureToImperial(global.gasPseudoCriticalTemperature)
  }
});