import React, { useContext, useEffect, useState } from "react";
import PaginationBar from "./PaginationBar";


const Pagination = props => {
    // Pagination variables
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage, setJobsPerPage] = useState(5);
    const [indexOfLastJob, setIndexOfLastJob] = useState(currentPage * jobsPerPage);
    const [indexOfFirstJob, setIndexOfFirstJob] = useState(indexOfLastJob - jobsPerPage);
    const [currentJobs, setCurrentJobs] = useState([]);
    const [pageNumbers, setPageNumbers] = useState(new Set());
    
    // Determines the number of pages
    useEffect(() => {
        if (props.curvedLines) {
            for (var i = 1; i <= Math.ceil(props.curvedLines.length / jobsPerPage); i++) {
                pageNumbers.add(i)
            }

            setCurrentJobs(props.curvedLines.slice(indexOfFirstJob, indexOfLastJob));
        }
    }, [props.curvedLines])

    useEffect(() => {
        const indexOfLastJob = currentPage * jobsPerPage;
        const indexOfFirstJob = indexOfLastJob - jobsPerPage;
        const currentJobs = props.curvedLines.slice(indexOfFirstJob, indexOfLastJob);

        // Update pagination variables
        setIndexOfLastJob(indexOfLastJob);
        setIndexOfFirstJob(indexOfFirstJob);
        setCurrentJobs(currentJobs);
    }, [currentPage])
    
    return (
        <div className="pumperSummary">
            <div className="curve-title-container">
                <div className="curve-title">Recommendation</div>
                <div className="curve-title">Chart Inputs</div>
                {!props.userCharts &&
                    <div className="curve-title">Created By</div>
                }
                <div className="curve-title">Date Created (UTC Time)</div>
            </div>
            <div className="clearfix" />
            <div className="pumperLinesData">
                {currentJobs && (
                    <table className="fullWidth" id="table">
                        <tbody>
                            {currentJobs}
                        </tbody>
                    </table>
                )}
            </div>
            <div className="clearfix"/>
            <PaginationBar pageNumbers={pageNumbers} currentPage={currentPage} onPageClick={(page) => setCurrentPage(page)}/>
        </div>
    );
};

export default Pagination