import React, {useEffect, useState} from "react";
import MainPage from "./Components/MainPage";
import {useAuth0} from "./Components/Auth/auth0-wrapper";
import "./custom.css";
import Header from "./Components/Header/Header";
import {SelectedProjectContext} from "./Context/SelectedProjectContext";
import {UserTypeContext} from "./Context/UserTypeContext";
import Loader from "./Components/Common/Loader/Loader";
import CacheBuster from "./Components/Common/CacheBuster";
import ErrorBoundary from "./Components/Common/Error/ErrorBoundary";
import {Route, BrowserRouter, Switch} from "react-router-dom";
import CustomerPage from "./Components/CustomerPage";
import AccessControl from "./Components/Common/AccessControl/AccessControl";
import {AccessType} from "./Components/Auth/accessControlService";
import PageNotFound from "./Components/Common/PageNotFound";
import ProjectPage from "./Components/ProjectPage";
import {Unauthorized} from "./Components/Common/Unauthorized";
import NoPermissions from "./Components/Common/NoPermissions";
import SuccessfulTransaction from "./Components/Stripe/SuccessfulTransaction";
import {TokenContext} from "./Context/TokenContext";
import CanceledTransaction from "./Components/Stripe/CanceledTransaction";
import fetchWithTimeout from "./Helpers/FetchWithTimeout";
import {ClientIdContext} from "./Context/ClientIdContext";

const App = () => {
    const {
        loading,
        isAuthenticated,
        loginWithRedirect,
        getTokenSilently
    } = useAuth0();

    global.auth0ClientId = process.env.REACT_APP_AUTH0_CLIENTID;

    const [selectedProject, setSelectedProject] = useState(null);

    const [userType, setUserType] = useState(null);
    const [token, setToken] = useState(null);
    const [tokens, setTokens] = useState(0);
    const [frontendClientId, setFrontEndClientId] = useState(null)
    

    const fetchFrontendClientId = async () => {
        let myRequest = new Request(global.apiEndpoint + "settings/keys");

        await fetchWithTimeout(myRequest).then(function (response) {
            if (response.status === 200) {
                return response.json();
            }
        }).then(function (jsonResponse) {
            if (process.env.REACT_APP_LOCAL_CACHE_TIME !== undefined) {
                setFrontEndClientId(process.env.REACT_APP_AUTH0_CLIENTID)
            }else {
                setFrontEndClientId(jsonResponse.auth0ClientID)
            }
        })
    }
    
    const fetchToken = async () => {
        const auth0Token = await getTokenSilently();
        setToken(auth0Token);
    };

    useEffect(() => {
        if (loading || isAuthenticated) {
            return;
        }
        const fn = async () => {
            await loginWithRedirect({
                appState: {targetUrl: window.location.pathname}
            });
        };

        fn().then();
    }, [loading, isAuthenticated, loginWithRedirect]);

    useEffect(() => {
        if (!loading) {
            fetchToken().then(
                fetchFrontendClientId
            );
            
        }
    }, [loading]);

    if (loading) {
        return (
            <div className="center">
                <Loader/>
            </div>
        );
    }
    return (
        <CacheBuster>
            {({loading, isLatestVersion, refreshCacheAndReload}) => {
                if (loading)
                    return null;
                if (!loading && !isLatestVersion) {
                    refreshCacheAndReload();
                }

                return (
                    <>
                        <TokenContext.Provider value={{tokens, setTokens}}>

                            <UserTypeContext.Provider
                                value={{userType: userType, setUserType: setUserType}}
                            >
                                <SelectedProjectContext.Provider
                                    value={{
                                        selectedProject: selectedProject,
                                        setSelectedProject: setSelectedProject
                                    }}
                                >
                                    <ClientIdContext.Provider value={{
                                        frontendClientId: frontendClientId,
                                        setFrontEndClientId: setFrontEndClientId
                                    }}>
                                    {!loading && token && (
                                        <>
                                            <BrowserRouter>
                                                <ErrorBoundary>
                                                    <Header token={token}/>
                                                    <div className="body-wrapper">
                                                        <Switch>
                                                            <Route exact path="/">
                                                                {selectedProject &&
                                                                <MainPage token={token}/>
                                                                }
                                                            </Route>
                                                            <Route path="/create-customer">
                                                                <AccessControl
                                                                    accessTypes={[AccessType.IsSalesStaff, AccessType.IsSiteAdmin]}
                                                                    renderNoAccess={() => Unauthorized({path: "/create-customer"})}
                                                                >
                                                                    <CustomerPage token={token}/>
                                                                </AccessControl>
                                                            </Route>
                                                            <Route path="/create-project">
                                                                <AccessControl
                                                                    accessTypes={[AccessType.IsSalesStaff, AccessType.IsSiteAdmin]}
                                                                    renderNoAccess={() => Unauthorized({path: "/create-project"})}
                                                                >
                                                                    <ProjectPage token={token}/>
                                                                </AccessControl>
                                                            </Route>
                                                            <Route path="/no-projects-added" exact
                                                                   component={NoPermissions}/>
                                                            <Route path="/success.html">
                                                                <SuccessfulTransaction token={token}/>
                                                            </Route>
                                                            <Route path="/canceled.html">
                                                                <CanceledTransaction/>
                                                            </Route>
                                                            <Route path="*" exact component={PageNotFound}
                                                                   status={404}/>
                                                        </Switch>
                                                    </div>
                                                </ErrorBoundary>
                                            </BrowserRouter>
                                        </>
                                    )}
                                    </ClientIdContext.Provider>
                                </SelectedProjectContext.Provider>
                            </UserTypeContext.Provider>
                        </TokenContext.Provider>
                    </>
                );
            }}
        </CacheBuster>
    );
};

export default App;