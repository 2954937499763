import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
const FetchWithTimeout = (url, options = {}) => {
    const AbortController = window.AbortController;
    let { timeout = 120000, ...rest } = options;
    if (rest.signal) throw new Error("Signal not supported in timeoutable fetch");
    const controller = new AbortController();
    const { signal } = controller;
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
            controller.abort();
        }, timeout);
        fetch(url, { signal, ...rest })
            .finally(() => clearTimeout(timer))
            .catch(function(error) {
                resolve({status:500})
            })
            .then(resolve, reject)
    });
};
export default FetchWithTimeout;