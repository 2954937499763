import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { Auth0Provider } from "./Components/Auth/auth0-wrapper";
import IdleTimer from "./Components/IdleTimer";


const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const history = createBrowserHistory({ basename: baseUrl });

const rootElement = document.getElementById("root");
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};
ReactDOM.render(
  <Auth0Provider onRedirectCallback={onRedirectCallback}>
    <BrowserRouter basename={baseUrl}>
      <IdleTimer />
    </BrowserRouter>
  </Auth0Provider>,
  rootElement
);

registerServiceWorker();

