import React, { Fragment, useContext } from "react";
import { UserSettingsContext } from "../../Context/UserSettingsContext";
import Api from "../../Helpers/Api";
import { SelectedProjectContext } from "../../Context/SelectedProjectContext";
import { useAuth0 } from "../Auth/auth0-wrapper";
import { newMetadataValues } from "../../Helpers/MetadataHelper";

const UnitsOfMeasureToggle = props => {
  const { user } = useAuth0();
  const { isMetric, setIsMetric } = useContext(UserSettingsContext);
  const { selectedProject } = useContext(SelectedProjectContext);
  const api = new Api();

  const saveUserMetadata = async () => {
    let newMeta = newMetadataValues(
      user,
      selectedProject.customerName,
      selectedProject.projectName,
      selectedProject.projectId,
      selectedProject.customerId,
      !isMetric ? 2 : 1
    );
    await api
      .saveUserMetadata(
        global.apiEndpoint,
        props.token,
        newMeta,
        selectedProject.customerId,
        selectedProject.projectId
      )
      .then(response => {
        if (response.status === 200) {
          window.location.reload();
        }
      });
  };

  return (
    <>
      <div className="projectPreferences">
        <div className="projectInfoDataContainer">
          <div className="projectInfoDataLabel">Unit Of Measure</div>
          <div className="projectInfoValue" style={{ float: "left" }}>
            <label className="units-of-measure-toggle">
              <input
                type="checkbox"
                value={isMetric.toString()}
                onChange={() => {
                  setIsMetric(!isMetric);
                  saveUserMetadata().then();
                }}
              />
              <span className="units-of-measure-slider" />

              <div className="units-of-measure-text-container">
                <span
                  className={`units-of-measure-text${
                    !isMetric ? "--active" : ""
                  }`}
                >
                  Imperial
                </span>
                <span
                  className={`units-of-measure-text${
                    isMetric ? "--active" : ""
                  }`}
                >
                  Metric
                </span>
              </div>
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitsOfMeasureToggle