import React, {useContext, useEffect, useState} from "react";
import {ReactComponent as WhiteUp} from "../images/whiteUp.svg";
import {ReactComponent as WhiteDown} from "../images/whiteDown.svg";
import {AccessType} from "./Auth/accessControlService";
import AccessControl from "./Common/AccessControl/AccessControl";
import {SelectedProjectContext} from "../Context/SelectedProjectContext";
import {UserTypeContext} from "../Context/UserTypeContext";
import DetermineUserType from "../Helpers/DetermineUserType";

const MainMenu = props => {
    const [chartPageToggled, setChartPageToggled] = useState(true);
    const [savedChartsPageToggled, setSavedChartsPageToggled] = useState(false);
    const [projectChartsPageToggled, setProjectChartsPageToggled] = useState(
        false
    );
    const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
    const [configToggled, setConfigToggled] = useState(false);
    const [settingsToggled, setSettingsToggled] = useState(false);
    const [paymentsPageToggled, setPaymentsPageToggled] = useState(false);
    const [usersPageToggled, setUsersPageToggled] = useState(false);

    const {selectedProject} = useContext(SelectedProjectContext);
    const {userType, setUserType} = useContext(UserTypeContext);

    useEffect(() => {
        if (userType === undefined || userType === null) {
            DetermineUserType(
                global.apiEndpoint,
                props.token,
                selectedProject.projectId,
                selectedProject.customerId
            ).then(type => {
                setUserType(type);
            });
        }
    }, []);

    useEffect(() => {
        requestChartPage();
        setShowSettingsDropdown(false); 
    }, [selectedProject.projectId]);


    const toggleSettingsDropdown = () => {
        setShowSettingsDropdown(!showSettingsDropdown);
        setChartPageToggled(false);
        setSavedChartsPageToggled(false);
        setSettingsToggled(true);
        setProjectChartsPageToggled(false);
        setUsersPageToggled(false);
        setPaymentsPageToggled(false);
    };

    const requestConfiguration = () => {
        props.onProjectConfigClick();
        setChartPageToggled(false);
        setSavedChartsPageToggled(false);
        setConfigToggled(true);
        setSettingsToggled(true);
        setProjectChartsPageToggled(false);
        setUsersPageToggled(false);
        setPaymentsPageToggled(false);
    };

    const requestChartPage = () => {
        props.onChartClick();
        setChartPageToggled(true);
        setSavedChartsPageToggled(false);
        setConfigToggled(false);
        setSettingsToggled(false);
        setProjectChartsPageToggled(false);
        setUsersPageToggled(false);
        setPaymentsPageToggled(false);
    };

    const requestSavedChartsPage = () => {
        props.onSavedChartsClick();
        setChartPageToggled(false);
        setSavedChartsPageToggled(true);
        setConfigToggled(false);
        setSettingsToggled(false);
        setProjectChartsPageToggled(false);
        setUsersPageToggled(false);
        setPaymentsPageToggled(false);
    };

    const requestPaymentsPage = () => {
        props.onPaymentsPageClick();
        setChartPageToggled(false);
        setSavedChartsPageToggled(false);
        setConfigToggled(false);
        setSettingsToggled(false);
        setProjectChartsPageToggled(false);
        setUsersPageToggled(false);
        setPaymentsPageToggled(true);
    };

    const requestProjectChartPage = () => {
        props.onProjectChartPageClick();
        setChartPageToggled(false);
        setSavedChartsPageToggled(false);
        setConfigToggled(false);
        setSettingsToggled(false);
        setProjectChartsPageToggled(true);
        setUsersPageToggled(false);
        setPaymentsPageToggled(false);
    };

    const requestManageUsersPage = () => {
        props.onUsersPageClick();
        setChartPageToggled(false);
        setSavedChartsPageToggled(false);
        setConfigToggled(false);
        setSettingsToggled(true);
        setProjectChartsPageToggled(false);
        setUsersPageToggled(true);
        setPaymentsPageToggled(false);
    };

    let savedChartsPageItemState = savedChartsPageToggled
        ? "siteTitle siteTitleActive menuItem menuItemActive"
        : "siteTitle menuItem";
    let chartPageItemState = chartPageToggled
        ? "siteTitle siteTitleActive menuItem menuItemActive"
        : "siteTitle menuItem";
    let settingsMenuItemState = settingsToggled
        ? "siteTitle siteTitleActive menuItem menuItemActive"
        : "siteTitle menuItem";
    let configurationItemState = configToggled
        ? "wellTitle wellTitleActive subMenuItem subMenuItemActive"
        : "wellTitle subMenuItem";
    let projectChartState = projectChartsPageToggled
        ? "siteTitle siteTitleActive menuItem menuItemActive"
        : "siteTitle menuItem";
    let manageUsersPageItemState = usersPageToggled
        ? "wellTitle wellTitleActive subMenuItem subMenuItemActive"
        : "wellTitle subMenuItem";
    let paymentsPageItemState = paymentsPageToggled
        ? "siteTitle siteTitleActive menuItem menuItemActive"
        : "siteTitle menuItem";

    return (
        <>
            {userType && (
                <AccessControl
                    accessTypes={[
                        AccessType.IsSiteAdmin,
                        AccessType.IsProjectViewer,
                        AccessType.IsCustomerViewer,
                        AccessType.IsStaff,
                        AccessType.IsSalesStaff
                    ]}
                    renderNoAccess={() => ""}
                >
                    <div>
                        <h1
                            onClick={() => requestChartPage()}
                            className={chartPageItemState}
                        >
                            Generate a Chart
                        </h1>
                    </div>
                </AccessControl>
            )}
            {userType && (
                <AccessControl
                    accessTypes={[
                        AccessType.IsSiteAdmin,
                        AccessType.IsStaff,
                        AccessType.IsCustomerViewer,
                        AccessType.IsProjectViewer,
                        AccessType.IsSalesStaff
                    ]}
                    renderNoAccess={() => ""}
                >
                    <div>
                        <h1
                            onClick={() => requestSavedChartsPage()}
                            className={savedChartsPageItemState}
                        >
                            Saved Charts
                        </h1>
                    </div>
                </AccessControl>
            )}

            {userType && (
                <AccessControl
                    accessTypes={[AccessType.IsSiteAdmin, AccessType.IsStaff, AccessType.IsSalesStaff]}
                    renderNoAccess={() => ""}
                >
                    <div>
                        <h1
                            onClick={() => requestProjectChartPage()}
                            className={projectChartState}
                        >
                            Project Saved Charts
                        </h1>
                    </div>
                </AccessControl>
            )}
            {userType && (
                <AccessControl
                    accessTypes={[AccessType.IsProjectViewer, AccessType.IsCustomerViewer, AccessType.IsSiteAdmin]}
                    renderNoAccess={() => ""}
                >
                    <div>
                        <h1
                            onClick={() => requestPaymentsPage()}
                            className={paymentsPageItemState}
                        >
                            Purchase Tokens
                        </h1>
                    </div>
                </AccessControl>
            )}

            {userType && (
                <AccessControl
                    accessTypes={[
                        AccessType.IsSiteAdmin,
                        AccessType.IsStaff,
                        AccessType.IsCustomerViewer,
                        AccessType.IsProjectViewer,
                        AccessType.IsSalesStaff
                    ]}
                    renderNoAccess={() => ""}
                >
                    <div>
                        <>
                            <h1
                                onClick={() => toggleSettingsDropdown()}
                                className={settingsMenuItemState}
                            >
                                Project Info
                                {showSettingsDropdown && <WhiteUp className="floatRight"/>}
                                {!showSettingsDropdown && <WhiteDown className="floatRight"/>}
                            </h1>
                            {showSettingsDropdown && (
                                <>
                                    <div>
                                        <div
                                            onClick={() => requestConfiguration()}
                                            className={configurationItemState}
                                        >
                                            Configuration
                                        </div>
                                        <AccessControl
                                            accessTypes={[AccessType.IsSalesStaff, AccessType.IsSiteAdmin]}
                                            renderNoAccess={() => ""}
                                        >
                                            <div onClick={() => requestManageUsersPage()}
                                                 className={manageUsersPageItemState}>
                                                Manage Users
                                            </div>
                                        </AccessControl>
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </AccessControl>
            )}
        </>
    );
};

export default MainMenu