import React from 'react';
import Error from './Error/index';

const PageNotFound = () => (
  <Error title="Nothing Here">
    {
      "Unfortunately, you have requested utter nonsense and we can’t do anything for you"
    }
  </Error>
);

export default PageNotFound