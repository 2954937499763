import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import Api from "../Helpers/Api";
import Chart from "./Chart/Chart";
import CurveLine from "./Curves/CurveLine";
import { UserDataContext } from "../Context/UserDataContext";
import { SavedChartContext } from "../Context/SavedChartContext";
import "./Curves/Curves.css";
import { SelectedProjectContext } from "../Context/SelectedProjectContext";
import { useAuth0 } from "./Auth/auth0-wrapper";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import autoTable from "jspdf-autotable";
import SmallLogo from "../images/SmallLogo.png";
import { UserSettingsContext } from "../Context/UserSettingsContext";
import { UserSavedChartSettingsContext } from "../Context/UserSavedChartSettingsContext";
import { CurvedLinesContext } from "../Context/CurvedLinesContext";
import Pagination from "./Common/Pagination/Pagination";

const UserCurvesPage = props => {
  const api = new Api();
  const { setUserData } = useContext(UserDataContext);
  const { savedChartData } = useContext(SavedChartContext);
  const { curvedLines, setCurvedLines, updateCurvedLines } = useContext(
    CurvedLinesContext
  );
  const { user } = useAuth0();
  const [userId] = useState(user["sub"]);
  const { selectedProject } = useContext(SelectedProjectContext);
  const { isMetric } = useContext(UserSettingsContext);
  const { userSavedChartSettings } = useContext(UserSavedChartSettingsContext);

  const requestChartPage = async () => {
    props.onBackClick();
  };

  const getUserDataByUserId = async () =>
    await api
      .getProjectByProjectId(selectedProject.projectId, props.token)
      .then(response => {
        if (response.status === 200) {
          const data = response.data.recommendedProducts;
          const filteredDataByUser = data.filter(
            project => project.userId === userId
          );

          setUserData(filteredDataByUser);

          const tempCurvedLines = filteredDataByUser.map(recommendation => {
            return (
              <CurveLine onBackClick={requestChartPage} token={props.token}>
                {recommendation}
              </CurveLine>
            );
          });
          setCurvedLines({
            lines: tempCurvedLines,
            prevLength: tempCurvedLines.length
          });
        }
      })
      .catch(e => {
        setCurvedLines({
          lines: [],
          prevLength: 0
        });
      });

  useEffect(() => {
    if (!curvedLines.initCheck) {
      getUserDataByUserId(userId).then();
      updateCurvedLines("initCheck", true);
      updateCurvedLines(
        "prevGuid",
        `${selectedProject.customerId}-${selectedProject.projectId}`
      );
    } else if (
      curvedLines.prevGuid !==
      `${selectedProject.customerId}-${selectedProject.projectId}`
    ) {
      getUserDataByUserId(userId).then();
      updateCurvedLines(
        "prevGuid",
        `${selectedProject.customerId}-${selectedProject.projectId}`
      );
    } else if (curvedLines.lines.length !== curvedLines.prevLength) {
      getUserDataByUserId(userId).then();
    }
  }, [selectedProject.projectId]);

  const printPdfReport = () => {
    window.scrollTo(0, 0);
    const input = document.getElementById("printThis");
    html2canvas(input).then(canvas => {
      const gasFlowRateUnits = isMetric ? "e\xB3Sm\xB3/d" : "MMSCFD";
      const liquidFlowRateUnits = isMetric ? "m\xB3/d" : "BBL/d";

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape");
      // Page 1
      pdf.addImage(SmallLogo, "PNG", 5, 5);

      pdf.addImage(imgData, "PNG", 10, 20, 275, 75);

      autoTable(pdf, {
        startY: 100,
        body: [
          ["Recommendation", userSavedChartSettings.recommendation],
          ["Curve Creation Date (UTC)", userSavedChartSettings.creationDate],
          ["Pressure", userSavedChartSettings.pressure],
          ["Temperature", userSavedChartSettings.temperature],
          ["Gas Flow Rate", userSavedChartSettings.gasFlowRate],
          ["Liquid Flow Rate", userSavedChartSettings.liquidFlowRate],
          ["Water Cut Percentage", userSavedChartSettings.waterCutPercentage],
          ["Sand Size", userSavedChartSettings.sandSize],
          [
            "Gas Pseudo Critical Pressure",
            userSavedChartSettings.gasPseudoCriticalPressure
          ],
          [
            "Gas Pseudo Critical Temperature",
            userSavedChartSettings.gasPseudoCriticalTemperature
          ],
          ["Gas Molecular Weight", userSavedChartSettings.gasMolecularWeight]
        ]
      });

      pdf.addPage();
      const formattedData = savedChartData.datasets[0].data
        .slice(0, 34)
        .map(p => {
          return [
            `${p.x} ${gasFlowRateUnits}`,
            `${p.y} ${liquidFlowRateUnits}`
          ];
        });

      pdf.text("SANDTINEL OPERATING CURVE TABLE", 15, 10);

      autoTable(pdf, {
        startY: 15,
        head: [
          [`Gas (${gasFlowRateUnits})`, `Liquid (${liquidFlowRateUnits})`]
        ],
        body: formattedData
      });

      pdf.save("curve-report.pdf");
    });
  };

  return (
    <Row>
      <Col sm="12">
        <div className="projectInfoTitle">Saved Charts</div>
        {savedChartData && (
          <>
            <div id="printThis">
              <Chart chartData={savedChartData} />
            </div>
            <Button
              color="danger"
              outline
              className="col-12 col-lg-12"
              onClick={printPdfReport}
              size="md"
              style={{ marginBottom: "15px" }}
            >
              Generate PDF Report
            </Button>
          </>
        )}
        {curvedLines.lines.length > 0 && (
          <>
            <div className="blurbText">
              These are all the charts that have been generated by you. In this
              Menu you can go through your old generated charts.
            </div>
            <Pagination curvedLines={curvedLines.lines} userCharts={true} />
          </>
        )}
        {curvedLines.lines.length === 0 && (
          <>
            <div className="blurbText">
              No Charts have been generated by you yet. Generate charts to see
              them here.
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

export default UserCurvesPage