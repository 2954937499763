import Api from "./Api";

const api = new Api();

export const generateRecommendedCurve = async (
    values,
    unitOfMeasurement,
    token,
    country
) => await api.generateRecommendedCurve(
    values.pressure,
    values.temperature,
    values.gasFlowRate,
    values.liquidFlowRate,
    values.waterCutPercentage,
    values.sandSize,
    values.gasPseudoCriticalPressure,
    values.gasPseudoCriticalTemperature,
    values.gasMolecularWeight,
    unitOfMeasurement,
    token,
    country)
    .then((response) => {
        return response
    })
    