import React, { useContext, useEffect, useState } from "react";
import { Button } from "reactstrap";
import "./Chart.css";
import { generateRecommendedCurve } from "../../Helpers/GenerateCurve";
import { InputContext } from "../../Context/InputContext";
import { ChartContext } from "../../Context/ChartContext";
import InputSlider from "react-input-slider";
import { ChartSliderOptions } from "./ChartOptions";
import { ProductContext } from "../../Context/ProductContext";
import { CreateCurve } from "../../Helpers/CreateCurve";
import { CreateProductRecommendation } from "../../Helpers/CreateProductRecommendation";
import { ErrorContext } from "../../Context/ErrorContext";
import { UserTypeContext } from "../../Context/UserTypeContext";
import { UserSettingsContext } from "../../Context/UserSettingsContext";
import { TokenContext } from "../../Context/TokenContext";
import ConfirmationPopup from "../PopupComponent/ConfirmationPopUp";
import Popup from "reactjs-popup";
import "../PopupComponent/LandingPage.css";
import Api from "../../Helpers/Api";
import { SelectedProjectContext } from "../../Context/SelectedProjectContext";
import { useAuth0 } from "../Auth/auth0-wrapper";
import { toast } from "react-toastify";
import { CurvedLinesContext } from "../../Context/CurvedLinesContext";
import { ProjectCurvedLinesContext } from "../../Context/ProjectCurvedLinesContext";
import { ErrorMessage, Field, Formik, Form } from "formik";
import TextError from "../Formik/TextError";
import { ConversionUtility } from "./ConversionUtility";
import { metricValidationSchema } from "./Validation";
import { AdvancedSettingsToggleContext } from "../../Context/AdvancedSettingsToggleContext";
import { FreeTokensContext } from "../../Context/FreeTokensContext";

const ChartSettings = props => {
  const api = new Api();
  const { user } = useAuth0();
  const { input, setInput } = useContext(InputContext);
  const { setDisplayData } = useContext(ChartContext);
  const { setProduct } = useContext(ProductContext);
  const { setError } = useContext(ErrorContext);
  const { isMetric } = useContext(UserSettingsContext);
  const { selectedProject } = useContext(SelectedProjectContext);
  const { updatePrevLength } = useContext(CurvedLinesContext);
  const { updateProjectPrevLength } = useContext(ProjectCurvedLinesContext);
  const { userType } = useContext(UserTypeContext);
  const [initialValues] = useState(input);
  const [pressure, setPressure] = useState(input.pressure);
  const [temperature, setTemperature] = useState(input.temperature);
  const [gasFlowRate, setGasFlowRate] = useState(input.gasFlowRate);
  const [liquidFlowRate, setLiquidFlowRate] = useState(input.liquidFlowRate);
  const [waterCutPercentage, setWaterCutPercentage] = useState(input.waterCutPercentage);
  const [sandSize, setSandSize] = useState(input.sandSize);
  const [gasPseudoCriticalPressure, setGasPseudoCriticalPressure] = useState(input.gasPseudoCriticalPressure);
  const [gasPseudoCriticalTemperature, setGasPseudoCriticalTemperature] = useState(input.gasPseudoCriticalTemperature);
  const [gasMolecularWeight, setGasMolecularWeight] = useState(input.gasMolecularWeight);
  const [generateButtonClicked, setGenerateButtonClicked] = useState(false);
  const [userId] = useState(user["sub"]);
  const { toggleAdvancedSettings } = useContext(AdvancedSettingsToggleContext);
  const { tokens, setTokens } = useContext(TokenContext);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [generateButtonDisabled, setGenerateButtonDisabled] = useState(false);
  const [buttonPointerEvent] = useState("auto");
  const [convertedValues] = useState(ConversionUtility(true));
  const { freeTokens, setFreeTokens } = useContext(FreeTokensContext);

  toast.configure();

  const saveRecommendation = async (recommendation) =>
    await api.saveRecommendation(
      recommendation,
      props.token,
      selectedProject.customerId, 
      selectedProject.projectId, 
      userType
    );

  useEffect(() => {
    if (confirmClicked)
      onGenerateButtonClick().then(() => {
        setConfirmClicked(false);
      });
  }, [confirmClicked]);

  const confirmPopup = () => {
    setConfirmClicked(true);
    setGenerateButtonClicked(false);
  };

  const cancelPopup = () => {
    setGenerateButtonClicked(false);
  };
  
  const onGenerateButtonClick = async () => {
    setGenerateButtonDisabled(true);
    if (userType === "ProjectViewer" && tokens === 0 && freeTokens === 0) return;

    const values = {
      pressure,
      temperature,
      gasFlowRate,
      liquidFlowRate,
      waterCutPercentage,
      sandSize,
      gasPseudoCriticalPressure,
      gasPseudoCriticalTemperature,
      gasMolecularWeight
    };
    setInput(values);

    let unitOfMeasurement = isMetric ? "Metric" : "Imperial";
    await generateRecommendedCurve(
      values,
      unitOfMeasurement,
      props.token,
      selectedProject.country
    )
      .then(async response => {
        if (response.status === 200) {
          setDisplayData(
            CreateCurve(
              response.data.curve,
              [values.gasFlowRate, values.liquidFlowRate],
              isMetric
            )
          );
          setProduct(CreateProductRecommendation(response.data));

          // Update length of curved lines, this is to notify curvedLines component that it needs to recall the API
          updatePrevLength();
          updateProjectPrevLength();

          // Notify user
          toast.success("Success: Curve generated.", {
            position: toast.POSITION.TOP_RIGHT
          });
          setError("none");
          
          await saveRecommendation({
            pressure: response.data.curveInput.pressure,
            temperature: response.data.curveInput.temperature,
            gasFlowRate: response.data.curveInput.gasFlowRate,
            liquidFlowRate: response.data.curveInput.liquidFlowRate,
            waterCutPercentage: response.data.curveInput.waterCutPercentage,
            sandSize: response.data.curveInput.sandSize,
            sphereSize: response.data.sphereSize,
            sandLock: response.data.sandLockType,
            gasPseudoCriticalPressure:
              response.data.curveInput.gasPseudoCriticalPressure,
            gasPseudoCriticalTemperature:
              response.data.curveInput.gasPseudoCriticalTemperature,
            gasMolecularWeight: response.data.curveInput.gasMolecularWeight,
            projectId: selectedProject.projectId,
            recommendation: response.data.productType,
            operatingCurveType: response.data.operatingCurveType,
            pressureClass: response.data.pressureLimitsByClass,
            userId: userId,
            userName: user["nickname"],
            frontendClientId: user["frontendClientId"],
            email: user["email"]
          }).then((response) => {
            if (userType === "ProjectViewer") {
              if(response.status === 200) {
                setFreeTokens(response.data.freeTokensLeft)
                setTokens(response.data.tokensLeft)
              }
            }
          });
          setGenerateButtonDisabled(false);
        } else if (response.status === 204) {
          toast.error("Failure: Curve not generated, no curve found.", {
            position: toast.POSITION.TOP_RIGHT
          });
          setError("notFound");
          setDisplayData(null);
          setGenerateButtonDisabled(false);
        }
      })
      .catch(e => {
        toast.error(`Error: ${e}`);
        setError(e);
      });
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={metricValidationSchema()}
      onSubmit={() => {}}
    >
      {formik => {
        return (
          <Form>
            <div className="projectPreferences">
              <div className="d-flex flex-column full-flex">
                <div className="chart-settings-container">
                  <div className="couple">
                    <div className="projectInfoDataLabel">Pressure</div>
                    <div className="projectInfoValue">
                      <InputSlider
                        axis="x"
                        xstep={convertedValues.pressure.step}
                        xmin={convertedValues.pressure.min}
                        xmax={convertedValues.pressure.max}
                        x={pressure}
                        onChange={({ x }) => {
                          setPressure(x);
                          formik.values.pressure = x;
                        }}
                        styles={ChartSliderOptions}
                      />
                      <br />
                      <Field
                        name="pressure"
                        type="number"
                        placeholder="Pressure"
                        value={pressure}
                        onChange={e => {
                          setPressure(e.target.value);
                          formik.values.pressure = e.target.value;
                        }}
                      />
                      <span> {convertedValues.pressure.units}</span>
                      <ErrorMessage component={TextError} name="pressure" />
                    </div>
                  </div>
                  <div className="couple">
                    <div className="projectInfoDataLabel">Temperature</div>
                    <div className="projectInfoValue">
                      <InputSlider
                        axis="x"
                        xstep={convertedValues.temperature.step}
                        xmin={convertedValues.temperature.min}
                        xmax={convertedValues.temperature.max}
                        x={temperature}
                        onChange={({ x }) => {
                          setTemperature(x);
                          formik.values.temperature = x;
                        }}
                        styles={ChartSliderOptions}
                      />
                      <br />
                      <Field
                        name="temperature"
                        type="number"
                        placeholder="Temperature"
                        value={temperature}
                        onChange={e => {
                          setTemperature(e.target.value);
                          formik.values.temperature = e.target.value;
                        }}
                      />
                      <span> {convertedValues.temperature.units}</span>
                      <ErrorMessage component={TextError} name="temperature" />
                    </div>
                  </div>
                  <div className="couple">
                    <div className="projectInfoDataLabel">Gas Flow Rate</div>
                    <div className="projectInfoValue">
                      <InputSlider
                        axis="x"
                        xstep={convertedValues.gasFlowRate.step}
                        xmin={convertedValues.gasFlowRate.min}
                        xmax={convertedValues.gasFlowRate.max}
                        x={gasFlowRate}
                        onChange={({ x }) => {
                          setGasFlowRate(x);
                          formik.values.gasFlowRate = x;
                        }}
                        styles={ChartSliderOptions}
                      />
                      <br />
                      <Field
                        name="gasFlowRate"
                        type="number"
                        placeholder="Gas Flow Rate"
                        value={gasFlowRate}
                        onChange={e => {
                          setGasFlowRate(e.target.value);
                          formik.values.gasFlowRate = e.target.value;
                        }}
                      />
                      <span> {convertedValues.gasFlowRate.units}</span>
                      <ErrorMessage component={TextError} name="gasFlowRate" />
                    </div>
                  </div>
                  <div className="couple">
                    <div className="projectInfoDataLabel">Liquid Flow Rate</div>
                    <div className="projectInfoValue">
                      <InputSlider
                        axis="x"
                        xstep={convertedValues.liquidFlowRate.step}
                        xmin={convertedValues.liquidFlowRate.min}
                        xmax={convertedValues.liquidFlowRate.max}
                        x={liquidFlowRate}
                        onChange={({ x }) => {
                          setLiquidFlowRate(x);
                          formik.values.liquidFlowRate = x;
                        }}
                        styles={ChartSliderOptions}
                      />
                      <br />
                      <Field
                        name="liquidFlowRate"
                        type="number"
                        placeholder="Liquid Flow Rate"
                        value={liquidFlowRate}
                        onChange={e => {
                          setLiquidFlowRate(e.target.value);
                          formik.values.liquidFlowRate = e.target.value;
                        }}
                      />
                      <span> {convertedValues.liquidFlowRate.units}</span>
                      <ErrorMessage
                        component={TextError}
                        name="liquidFlowRate"
                      />
                    </div>
                  </div>
                  <div className="couple">
                    <div className="projectInfoDataLabel">
                      Water Cut Percentage
                    </div>
                    <div className="projectInfoValue">
                      <InputSlider
                        axis="x"
                        xstep={convertedValues.waterCutPercentage.step}
                        xmin={convertedValues.waterCutPercentage.min}
                        xmax={convertedValues.waterCutPercentage.max}
                        x={waterCutPercentage}
                        onChange={({ x }) => {
                          setWaterCutPercentage(x);
                          formik.values.waterCutPercentage = x;
                        }}
                        styles={ChartSliderOptions}
                      />
                      <br />
                      <Field
                        name="waterCutPercentage"
                        type="number"
                        placeholder="Water Cut %"
                        value={waterCutPercentage}
                        onChange={e => {
                          setWaterCutPercentage(e.target.value);
                          formik.values.waterCutPercentage = e.target.value;
                        }}
                      />
                      <span> {convertedValues.waterCutPercentage.units}</span>
                      <ErrorMessage
                        component={TextError}
                        name="waterCutPercentage"
                      />
                    </div>
                  </div>
                  <div className="couple">
                    <div className="projectInfoDataLabel">Sand Size</div>
                    <div className="projectInfoValue">
                      <>
                        <InputSlider
                          axis="x"
                          xstep={convertedValues.sandSize.step}
                          xmin={convertedValues.sandSize.min}
                          xmax={convertedValues.sandSize.max}
                          x={sandSize}
                          onChange={({ x }) => {
                            setSandSize(x);
                            formik.values.sandSize = x;
                          }}
                          styles={ChartSliderOptions}
                        />
                        <br />
                        <Field
                          name="sandSize"
                          type="number"
                          placeholder="Sand Size"
                          value={sandSize}
                          onChange={e => {
                            setSandSize(e.target.value);
                            formik.values.sandSize = e.target.value;
                          }}
                        />
                        <span> {convertedValues.sandSize.units}</span>
                        <ErrorMessage component={TextError} name="sandSize" />
                      </>
                    </div>
                  </div>
                  {toggleAdvancedSettings && (
                    <>
                      <div className="couple">
                        <div
                          className="projectInfoDataLabel"
                          style={{ paddingRight: "30px" }}
                        >
                          Gas Pseudo Critical Pressure
                        </div>
                        <div className="projectInfoValue">
                          <InputSlider
                            axis="x"
                            xstep={
                              convertedValues.gasPseudoCriticalPressure.step
                            }
                            xmin={convertedValues.gasPseudoCriticalPressure.min}
                            xmax={convertedValues.gasPseudoCriticalPressure.max}
                            x={gasPseudoCriticalPressure}
                            onChange={({ x }) => {
                              setGasPseudoCriticalPressure(x.toFixed(3));
                              formik.values.gasPseudoCriticalPressure = x.toFixed(
                                3
                              );
                            }}
                            styles={ChartSliderOptions}
                          />
                          <br />
                          <Field
                            name="gasPseudoCriticalPressure"
                            type="number"
                            placeholder="Gas Pseudo Critical Pressure"
                            value={gasPseudoCriticalPressure}
                            onChange={e => {
                              setGasPseudoCriticalPressure(e.target.value);
                              formik.values.gasPseudoCriticalPressure =
                                e.target.value;
                            }}
                          />
                          <span>
                            {" "}
                            {convertedValues.gasPseudoCriticalPressure.units}
                          </span>
                          <ErrorMessage
                            component={TextError}
                            name="gasPseudoCriticalPressure"
                          />
                        </div>
                      </div>
                      <div className="couple">
                        <div className="projectInfoDataLabel">
                          Gas Pseudo Critical Temperature
                        </div>
                        <div className="projectInfoValue">
                          <InputSlider
                            axis="x"
                            xstep={
                              convertedValues.gasPseudoCriticalTemperature.step
                            }
                            xmin={
                              convertedValues.gasPseudoCriticalTemperature.min
                            }
                            xmax={
                              convertedValues.gasPseudoCriticalTemperature.max
                            }
                            x={gasPseudoCriticalTemperature}
                            onChange={({ x }) => {
                              setGasPseudoCriticalTemperature(x.toFixed(1));
                              formik.values.gasPseudoCriticalTemperature = x.toFixed(
                                1
                              );
                            }}
                            styles={ChartSliderOptions}
                          />
                          <br />
                          <Field
                            name="gasPseudoCriticalTemperature"
                            type="number"
                            placeholder="Gas Pseudo Critical Temperature"
                            value={gasPseudoCriticalTemperature}
                            onChange={e => {
                              setGasPseudoCriticalTemperature(e.target.value);
                              formik.values.gasPseudoCriticalTemperature =
                                e.target.value;
                            }}
                          />
                          <span>
                            {" "}
                            {convertedValues.gasPseudoCriticalTemperature.units}
                          </span>
                          <ErrorMessage
                            component={TextError}
                            name="gasPseudoCriticalTemperature"
                          />
                        </div>
                      </div>
                      <div className="couple">
                        <div className="projectInfoDataLabel">
                          Gas Molecular Weight
                        </div>
                        <div className="projectInfoValue">
                          <InputSlider
                            axis="x"
                            xstep={convertedValues.gasMolecularWeight.step}
                            xmin={convertedValues.gasMolecularWeight.min}
                            xmax={convertedValues.gasMolecularWeight.max}
                            x={gasMolecularWeight}
                            onChange={({ x }) => {
                              setGasMolecularWeight(x);
                              formik.values.gasMolecularWeight = x;
                            }}
                            styles={ChartSliderOptions}
                          />
                          <br />
                          <Field
                            name="gasMolecularWeight"
                            type="number"
                            placeholder="Gas Molecular Weight"
                            value={gasMolecularWeight}
                            onChange={e => {
                              setGasMolecularWeight(e.target.value);
                              formik.values.gasMolecularWeight = e.target.value;
                            }}
                          />
                          <span>
                            {convertedValues.gasMolecularWeight.units}
                          </span>
                          <ErrorMessage
                            component={TextError}
                            name="gasMolecularWeight"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {userType === "ProjectViewer" ? (
                  <Button
                    color="danger"
                    className="float-right"
                    outline
                    onClick={() => {
                      setGenerateButtonClicked(true);
                    }}
                    size="md"
                    style={{
                      margin: "15px",
                      pointerEvents: tokens === 0 && freeTokens === 0 ? "none" : "auto"
                    }}
                    disabled={generateButtonDisabled || !formik.isValid}
                  >
                    Generate New Chart
                  </Button>
                ) : (
                  <Button
                    color="danger"
                    className="float-right"
                    outline
                    onClick={() => {
                      onGenerateButtonClick().then(() => {
                        setConfirmClicked(false);
                      });
                    }}
                    size="md"
                    style={{
                      margin: "15px",
                      pointerEvents: buttonPointerEvent
                    }}
                    disabled={generateButtonDisabled || !formik.isValid}
                  >
                    Generate New Chart
                  </Button>
                )}
              </div>
              <Popup open={generateButtonClicked} contentStyle={{"position": "relative", "background": "#333333", "width": "455px", "margin": "auto",
                "border": "1px solid #8e1b30", "padding": "5px 5px 15px 5px", "height": "105px"}} closeOnDocumentClick={false}>
                {() => (
                  // Fix pointer event stuff here, based on state of second popup
                  <div className="country-popup-container">
                    <ConfirmationPopup
                      onConfirm={confirmPopup}
                      onCancel={cancelPopup}
                    />
                  </div>
                )}
              </Popup>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChartSettings