import React, {useEffect} from "react";
import Error from "./Error/index";

const NoPermissions = () => (
    
  <Error title="No Access">
    {
      "No Projects Have been Assigned to you. Please contact support for further assistance."
    }
  </Error>
);

export default NoPermissions;