import React, { Component } from "react";
import { CurvedLinesContext } from "../../Context/CurvedLinesContext";

export class CurvedLinesProvider extends Component {
    constructor(props){
        super(props);
        this.state = {
            lines: [],
            prevLength: 0,
            prevGuid: null,
            initCheck: false
        };
    }
    
    // Increment prevLength
    updatePrevLength = () => {
        this.setState({
            prevLength: this.prevLength + 1
        })
    }
    
    // Update single value in state
    updateCurvedLines = (key, value) => {
        this.setState({ [key]: value })
    }
    
    // Update all values in state
    setCurvedLines = (value) => {
        this.setState(value);
    }
    
    render() {
        return (
            <CurvedLinesContext.Provider
                value={{
                    curvedLines: this.state,
                    setCurvedLines: this.setCurvedLines,
                    updateCurvedLines: this.updateCurvedLines,
                    updatePrevLength: this.updatePrevLength
                }}
            >
                {this.props.children}
            </CurvedLinesContext.Provider>
        );
    }
}