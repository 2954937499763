export enum NetworkRequestStatus {
  Successful,
  Failed
}


export enum UnitOfMeasurement{
  Default = 0,
  Imperial = 1,
  Metric = 2
}

export enum CheckProjectAdminAuthTypes {
  CheckingProjectAdmin = "CHECKING_USER_PROJECT_ADMIN",
  AuhtorizedProjectAdmin = "USER_AUTHORIZED_PROJECT_ADMIN",
  UnauthorizedProjectAdmin = "USER_UNAUTHORIZED_PROJECT_ADMIN"
}

export enum SubmitUserTypes {
  Uninitialized = "UNINIT_SUBMIT_USER",
  Submitting = "SUBMITTING_USER",
  Submitted = "SUBMITTED_USER",
  Error = "SUBMIT_USER_ERROR"
}

export enum GetUsersTypes {
  Uninitialized = "UNINIT_GET_USERS",
  Getting = "GETTING_USERS",
  Got = "GOT_USERS",
  Error = "GET_USERS_ERROR"
}

export const UnauthorizedError = "UNAUTHORIZED";
export const UnauthorizedSubmitError = "UNAUTHORIZED_SUBMIT";

export enum SaveUserMetadataTypes {
  Uninitialized = "UNINIT_SUBMIT_USER_METADATA",
  Submitting = "SUBMITTING_USER_METADATA",
  Submitted = "SUBMITTED_USER_METADATA",
  Error = "SUBMIT_USER_ERROR_METADATA"
}