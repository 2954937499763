import React from "react";
import { Button } from "reactstrap";
import "../PopupComponent/LandingPage.css"

const ConfirmationPopup = props => {
  return (
    <div
      className="confirmation-popup-container"
    >
      <div className="confirmation-popup-text-container">
        <div className="confirmation-popup-text">
          <strong>Are you sure you want to generate the curve with the provided inputs?</strong>
        </div>
        <div className="confirmation-popup-note">
          <strong>Note: This will use up one of your token to generate a curve.</strong>
        </div>
        <br/>
      </div>
      <div className="confirmation-button-container">
        <Button
          className="confirmation-confirm-button float-left"
          onClick={props.onConfirm}
          color="danger"
          outline
          size="sm"
        >
          Confirm
        </Button>
        <Button
          className="confirmation-back-button float-right"
          onClick={props.onCancel}
          color="danger"
          outline
          size="sm"
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationPopup