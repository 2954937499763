import React from 'react';
import './Pagination.css';

export default function PaginationBar(props) {
    const setToList = [...props.pageNumbers];

    // last page
    const decrementPage = async () => {
        if (props.currentPage > 1) {
            props.onPageClick(props.currentPage - 1);
        }
    }

    // next page
    const incrementPage = async () => {
        if (props.currentPage < Math.max(...setToList)) {
            props.onPageClick(props.currentPage + 1);
        }
    }

    const pageList = setToList.map(page => {
        const activePage = props.currentPage === page ? "pagination-element active-page" : "pagination-element inactive-page";

        return (
            <div className={activePage} onClick={() => props.onPageClick(page)}>
                {page}
            </div>
        )
    })

    return (
        <div className="pagination-bar">
            <div className="pagination-element" onClick={decrementPage}>&lt;</div>
            {pageList}
            <div className="pagination-element" onClick={incrementPage}>&gt;</div>
        </div>
    )
}
