import React from 'react';
import Error from './index';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false 
        };
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }
    
    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return <Error useStaticLink />
        }
        return this.props.children;
    }
}
export default ErrorBoundary;