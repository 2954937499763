import React, { useContext, useEffect, useState } from "react";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { SelectedProjectContext } from "../../Context/SelectedProjectContext";
import Api from "../../Helpers/Api";
import { UserTypeContext } from "../../Context/UserTypeContext";
import DetermineUserType from "../../Helpers/DetermineUserType";
import { Link } from "react-router-dom";
import { createBrowserHistory } from "history";

const NavMenu = props => {
  const history = createBrowserHistory({
    basename: window.location.href,
    forceRefresh: true
  });
  const { selectedProject, setSelectedProject } = useContext(
    SelectedProjectContext
  );
  const [menuItems, setMenuItems] = useState(null);
  const [title, setTitle] = useState("Select a Project");
  const { userType, setUserType } = useContext(UserTypeContext);

  const api = new Api();

  window.onload = () => {
    if (!window.location.hash) {
      window.location = `${window.location}#loaded`;
      window.location.reload();
    }
  };
  const handleMenuItemClick = project => {
    setTitle(`${project.value.customer} / ${project.value.name}`);
    setSelectedProject({
      projectId: project.value.id,
      projectName: project.value.name,
      customerId: project.value.customerId,
      customerName: project.value.customer,
      country: project.value.country,
      currency: project.value.country === "USA" ? "USD" : "CAD"
    });
  };
  const getAllCustomersWithProjects = async type => {
    await api.getAllCustomersWithProjects(props.token).then(response => {
      response.data.forEach(customer => {
        customer.projects_menu = [];
        customer.projects.forEach(project => {
          customer.projects_menu.push({
            name: project.projectName,
            id: project.projectId,
            customer: customer.customerName,
            customerId: customer.customerId,
            country: project.country
          });
        });
      });

      if (response.data) {
        if (type === "Staff" || type === "SiteAdmin" || type === "SalesStaff") {
          let lineItems = response.data.map((customer, i) => {
            return (
              <SubMenu key={i} label={customer.customerName}>
                {customer.projects_menu.map((project, j) => {
                  return (
                    <MenuItem
                      key={`${i}-${j}`}
                      value={project}
                      onClick={handleMenuItemClick}
                    >
                      <Link to={"/"} style={{ color: "black" }}>
                        {project.name}
                      </Link>
                    </MenuItem>
                  );
                })}
              </SubMenu>
            );
          });
          setMenuItems(lineItems);
        } else {
          if (response.data.length > 0) {
            let lineItems = response.data[0].projects_menu.map((project, i) => {
              return (
                <MenuItem value={project} onClick={handleMenuItemClick}>
                  <Link to={"/"} style={{ color: "black" }}>
                    {project.name}
                  </Link>
                </MenuItem>
              );
            });
            setMenuItems(lineItems);
          } else {
            history.push("/no-projects-added");
          }
        }
      }
    });
  };



  useEffect(() => {
    if (userType === undefined || userType === null) {
      DetermineUserType(global.apiEndpoint, props.token).then(type => {
        setUserType(type);
        getAllCustomersWithProjects(type).then(() => {
          if (selectedProject === null) {
            if (!window.location.hash) {
              window.location += "#loaded";
            }
          }
        });
      });
    }
  }, []);

  return (
    menuItems && (
      <Menu
        menuButton={
          <MenuButton styles={{ border: "none" }}>{title}</MenuButton>
        }
      >
        {menuItems}
      </Menu>
    )
  );
};

export default NavMenu