import React, { useState } from "react";
import SmallLogo from "../../images/SmallLogo.png";
import NavMenu from "../NavMenu/NavMenu";
import "./Header.css";
import { useAuth0 } from "../Auth/auth0-wrapper";
import { ProfileDropdown } from "../Common/ProfileDropdown/ProfileDropdown";
import { NavLink, Container, Row } from "reactstrap";

const Header = (props) => {
  const {user, logout } = useAuth0();
  const [settingsDropdownToggle, setSettingsDropdownToggle] = useState(false);

  const toggleSettings = () => {
    setSettingsDropdownToggle(!settingsDropdownToggle);
  };
  return (
    <Container fluid={true} className="header-wrapper">
      <Row className="header">
        <div className="smallLogoContainer">
          <img src={SmallLogo} className="smallLogo" alt="Sandtinel" />
        </div>
        <NavMenu token={props.token}/>
        {user && (
          <div className="profileImageContainer">
            <ProfileDropdown
              logout={logout}
              username={user.email}
              to={""}
              isOpen={settingsDropdownToggle}
              toggle={toggleSettings}
              tag={NavLink}
            />
          </div>
        )}
      </Row>
    </Container>
  );
};

export default Header;

