import React, {useContext, useEffect, useState} from "react";
import {Button, Table} from "reactstrap";
import {useAuth0} from "../Auth/auth0-wrapper";
import {SelectedProjectContext} from "../../Context/SelectedProjectContext";


const BeanieCheckout = props => {
        const {user} = useAuth0();
        const {selectedProject} = useContext(SelectedProjectContext);
        const [amount, setAmount] = useState(0);
        const [costPerToken, setCostPerToken] = useState(0);
        const [price, setPrice] = useState(0);
        const [quantity, setQuantity] = useState(1);
        const [beanie, setBeanie] = useState(null);
        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(false);
        const [currency, setCurrency] = useState(null)
        const [publishKey, setPublishKey] = useState(null)
        const [priceId, setPriceId] = useState(null)

        const fetchCheckoutSession = async ({token, email, priceId, tokensPurchased}) => {
            return fetch(`${global.apiEndpoint}Beanie/create-checkout-session?email=${email}&priceId=${priceId}&tokensPurchased=${tokensPurchased}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            }).then((res) => res.json());
        };

    
    
        const formatPrice = ({amount, currency, quantity}) => {
            const numberFormat = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency,
                currencyDisplay: 'symbol',
            });
            const parts = numberFormat.formatToParts(amount);
            let zeroDecimalCurrency = true;
            for (let part of parts) {
                if (part.type === 'decimal') {
                    zeroDecimalCurrency = false;
                }
            }
            amount = zeroDecimalCurrency ? amount : amount / 100;
            const total = (quantity * amount).toFixed(2);
            return numberFormat.format(total);
        };

        const formatUnitAmount = ({amount, currency}) => {
            const numberFormat = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency,
                currencyDisplay: 'symbol',
            });
            const parts = numberFormat.formatToParts(amount);
            let zeroDecimalCurrency = true;
            for (let part of parts) {
                if (part.type === 'decimal') {
                    zeroDecimalCurrency = false;
                }
            }
            amount = zeroDecimalCurrency ? amount : amount / 100;
            return numberFormat.format(amount);
        }

        useEffect(() => {
            async function fetchConfig() {
                // Fetch config from our backend.
                const {publicKey, unitAmount, currency, priceId} = await fetch(
                    `${global.apiEndpoint}Stripe/config?userType=${user["https://portal.sandtinel.com_app_metadata"].renterType}&currency=${selectedProject.currency}`, {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${props.token}`
                        }
                    }
                ).then((res) => res.json());
                // Make sure to call `loadStripe` outside of a component’s render to avoid
                // recreating the `Stripe` object on every render.
                setAmount(formatUnitAmount({amount: unitAmount, currency: currency}))
                setCurrency(currency)
                setPublishKey(publicKey)
                setCostPerToken(unitAmount)
                setPrice(formatPrice({
                    amount: unitAmount,
                    currency: currency,
                    quantity: quantity,
                }))
                setPriceId(priceId)
                setBeanie(window.OctobatBeanie(publicKey));
            }

            fetchConfig();
        }, []);

        useEffect(() => {
            if (currency != null)
                setPrice(formatPrice({
                    amount: costPerToken,
                    currency: currency,
                    quantity: quantity,
                }))
        }, [quantity])

        const requestSession = (sessionId) => {
            if (beanie != null)
                beanie.redirectToBeanie({
                    sessionId: sessionId
                })
                    .then(function (result) {
                        if (result.error) {
                            // If `redirectToBeanie` fails due to a browser or network
                            // error, display the localized error message to your customer.
                            let displayError = document.getElementById('sr-field-error');
                            displayError.textContent = result.error.message;
                        }
                    }).catch(function (error) {
                    // If `redirectToBeanie` can't be triggered because of invalid parameters
                    // or implementation, error, display a generic error message to your customer,
                    // and report the error to the console for debugging purposes, or send it to Sentry
                    // Sentry.log(error)
                    let displayError = document.getElementById('sr-field-error');
                    displayError.textContent = "Implementation error, please contact website administrator"
                })
        }

    const handleClick = async (event) => {
        // Call your backend to create the Checkout session.
        setLoading(true)
        
        
        const {response} = await fetchCheckoutSession({
            tokensPurchased: quantity,
            token: props.token,
            email: user.email,
            priceId: priceId
        });
        // When the customer clicks on the button, redirect them to Checkout.
         await requestSession(response.id);
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        if (error) {
           setError(error)
            setLoading(false)
        }
    };

        return (
          <>
            <div className="projectInfoTitle">Purchase Tokens</div>
            <Table dark responsive>
              <thead>
                <tr>
                  <th>Cost Per Token</th>
                  <th>Tokens</th>
                  <th>Subtotal</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <>
                  <tr>
                    <td>
                      <div className="curve-inner-container">
                        <div className="curve-column">{amount}</div>
                      </div>
                    </td>
                    <td>
                      <div className="curve-inner-container">
                        <Button
                          className="token-changer"
                          disabled={quantity === 1}
                          onClick={() =>
                            quantity !== 0
                              ? setQuantity(quantity - 1)
                              : setQuantity(0)
                          }
                        >
                          -
                        </Button>
                        <input
                          type="number"
                          id="quantity-input"
                          min="1"
                          value={quantity}
                          onChange={e => {
                              if(e.target.value > 0)
                                setQuantity(e.target.value);
                          }}
                          style={{
                            "max-width": "50px",
                            "text-align": "center",
                            margin: "0px 2px 0px 2px"
                          }}
                        />
                        <Button
                          className="token-changer"
                          onClick={() =>
                            quantity !== 0
                              ? setQuantity(quantity + 1)
                              : setQuantity(0)
                          }
                        >
                          +
                        </Button>
                      </div>
                    </td>
                    <td>
                      <div className="curve-inner-container">
                        <div className="curve-column">{price}</div>
                      </div>
                    </td>
                    <td>
                      <div className="curve-inner-container">
                        <div className="curve-column">
                          <Button
                            color="danger"
                            className="float-right"
                            outline
                            size="md"
                            style={{ width: "100%" }}
                            onClick={handleClick}
                            disabled={!beanie || loading}
                          >
                            {loading || !price
                              ? `Loading...`
                              : `Buy for ${price}`}
                          </Button>
                          <div className="sr-field-error">{error}</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              </tbody>
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              </tfoot>
            </Table>
          </>
        );
    }
;

export default BeanieCheckout;