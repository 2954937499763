export const MetricChartOptions = {
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Liquid (m\xB3/d)"
        }
      }
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Gas (e\xB3Sm\xB3/d)"
        }
      }
    ]
  },
  legend: {
    display: true,
    position: "bottom",
    onClick: e => e.stopPropagation()
  },
  tooltips: {
    mode: "index",
    intersect: false,
    position: "nearest",
    backgroundColor: "#000000",
    titleFontColor: "#ffffff",
    bodyFontColor: "#ffffff",
    titleFontFamily: "Calibri, Arial",
    titleFontSize: 12,
    bodyFontFamily: "Calibri, Arial",
    bodyFontSize: 12,
    callbacks: {
      title: () => "Operating Point",
      label: tooltipItem => {
        return [
          `Liquid: ${tooltipItem.yLabel} (m\xB3/d)`,
          `Gas: ${tooltipItem.xLabel} (e\xB3Sm\xB3/d)`
        ];
      }
    }
  },
  title: {
    display: true,
    text: "Operating Curve (Metric)"
  },
  responsive: true,
  maintainAspectRatio: false
};

export const ImperialChartOptions = {
  scales: {
    yAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Liquid (BBL/d)"
        }
      }
    ],
    xAxes: [
      {
        scaleLabel: {
          display: true,
          labelString: "Gas (MMSCFD)"
        }
      }
    ]
  },
  legend: {
    display: true,
    position: "bottom",
    onClick: e => e.stopPropagation()
  },
  tooltips: {
    mode: "index",
    intersect: false,
    position: "nearest",
    backgroundColor: "#000000",
    titleFontColor: "#ffffff",
    bodyFontColor: "#ffffff",
    titleFontFamily: "Calibri, Arial",
    titleFontSize: 12,
    bodyFontFamily: "Calibri, Arial",
    bodyFontSize: 12,
    callbacks: {
      title: () => "Operating Point",
      label: tooltipItem => {
        return [
          `Liquid: ${tooltipItem.yLabel} (BBL/d)`,
          `Gas: ${tooltipItem.xLabel} (MMSCFD)`
        ];
      }
    }
  },
  title: {
    display: true,
    text: "Operating Curve (Imperial)"
  },
  responsive: true,
  maintainAspectRatio: false
};

export const ChartSliderOptions = {
  track: {
    width: "100%"
  },
  thumb: {
    width: 20
  },
  active: {
    backgroundColor: "var(--sandtinel-red)"
  }
};
