// Convert To Metric

export const ConvertPSIToKPa = pressure => (pressure / 0.145038);

export const ConvertFahrenheitToCelsius = temperature => ((temperature - 32) * 5 / 9);

export const ConvertGasFlowRateToMetric = gasFlowRate => (gasFlowRate / 35.3827 * 1000);

export const ConvertLiquidFlowRateToMetric = liquidFlowRate => (liquidFlowRate / 6.29);

export const ConvertSandSizeToMetric = sandSize => {
    switch (sandSize) {
        case 140:
            return 100;
        case 100:
            return 150;
        case 80:
            return 180;
        case 60:
            return 250;
        case 50:
            return 300;
        default:
            return 0;
    }
};

export const ConvertGasPseudoCriticalPressureToMetric = gasPseudoCriticalPressure =>
    gasPseudoCriticalPressure / 14.22;

export const ConvertGasPseudoCriticalTemperatureToMetric= gasPseudoCriticalTemperature =>
    gasPseudoCriticalTemperature / 1.8

// Convert To Imperial
export const ConvertKPaToPSI = pressure => (pressure * 0.145038);

export const ConvertCelsiusToFahrenheit = temperature =>
    ((temperature * 9 / 5) + 32);

export const ConvertGasFlowRateToImperial = gasFlowRate =>
    (gasFlowRate * 35.3827 * 0.001);

export const ConvertLiquidFlowRateToImperial = liquidFlowRate =>
    (liquidFlowRate * 6.29);

export const ConvertSandSizeToImperial = sandSize => {
    switch (sandSize) {
        case 100:
            return 140;
        case 150:
            return 100;
        case 180:
            return 80;
        case 250:
            return 60;
        case 300:
            return 50;
        default:
            return 0;
    }
};

export const ConvertGasPseudoCriticalPressureToImperial = gasPseudoCriticalPressure =>
    gasPseudoCriticalPressure * 14.22;

export const ConvertGasPseudoCriticalTemperatureToImperial = gasPseudoCriticalTemperature =>
    gasPseudoCriticalTemperature * 1.8