import React, { useContext, useEffect, useState } from "react";
import { ProductContext } from "../../Context/ProductContext";
import {ParseRecommendation} from "../../Helpers/ParseRecommendation";

const RecommendationBox = () => {
  const { product } = useContext(ProductContext);
  const [productType, setProductType] = useState(null);
  
  useEffect(() => {
    if (product) setProductType(ParseRecommendation(product));
  }, [product]);
  return (
    <>
      {product && productType && (
        <div
          className="menuItem"
          style={{ paddingLeft: "0px", borderLeft: "0px", textAlign: "center"}}
        >
          Based on the Operating conditions provided we recommend you use{" "}
          {productType.inches}” {productType.tradeName}{" "}
          {productType.pressureClass} with {productType.sandLock}
        </div>
      )}
    </>
  );
};

export default RecommendationBox;