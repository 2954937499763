import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {FormattedMessage} from "react-intl";
import {Button} from "reactstrap";

export default function TimeoutPopup(props) {
    
    const closeModal = () => {
        props.setIsOpen(false);
        props.reset();
        props.popupTimeoutReset()
    }
    
    return (
        <>
            <Modal show={props.isOpen}>
                <div className="timeout-component">
                    <Modal.Header>
                        <Modal.Title>
                            Session Timeout
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <span id="warning-logo">&#9888; </span>
                        <span>
                            Your session is about to expire due to inactivity. To keep viewing, select Continue Session.
                        </span>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="continue-button"
                                onClick={closeModal}
                                color="warning"
                                outline
                                size="md">
                            <FormattedMessage id="continueSession.label" defaultMessage="Continue Session"/>
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </>
    );
}