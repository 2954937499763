import React, {useContext} from "react";
import {Button} from "reactstrap";
import Api from "../../Helpers/Api";
import {CreateCurve} from "../../Helpers/CreateCurve";
import "./Curves.css";
import {UserSettingsContext} from "../../Context/UserSettingsContext";
import {
    ConvertCelsiusToFahrenheit,
    ConvertGasFlowRateToImperial,
    ConvertGasPseudoCriticalPressureToImperial,
    ConvertGasPseudoCriticalTemperatureToImperial,
    ConvertKPaToPSI,
    ConvertLiquidFlowRateToImperial,
    ConvertSandSizeToImperial
} from "../../Helpers/UnitOfMeasurementConverter";
import {ConvertSandLock} from "../../Helpers/ConvertSandLock";
import {ActiveProjectChartContext} from "../../Context/ActiveProjectContext";
import {ProjectSavedChartContext} from "../../Context/ProjectSavedChartContext";
import {ProjectSavedChartSettingsContext} from "../../Context/ProjectSavedChartSettingsContext";
import {toast} from "react-toastify";
import {ParseRecommendation} from "../../Helpers/ParseRecommendation";

const CurveLineWithUser = props => {
    const api = new Api();
    const {setSavedProjectChartData} = useContext(ProjectSavedChartContext);

    const {isMetric} = useContext(UserSettingsContext);

    const {activeProjectChartGuid, setActiveProjectChartGuid} = useContext(
        ActiveProjectChartContext
    );

    const {setProjectSavedChartSettings} = useContext(
        ProjectSavedChartSettingsContext
    );

    const curveInput = props.children.curveInput;

    toast.configure();
    const recommendation = ParseRecommendation({type: props.children.productType, sandLock: curveInput.sandLock, sphereSize: curveInput.sphereSize, class : props.children.pressureLimitsByClass})


    const activeChart =
        activeProjectChartGuid === props.children.recommendationGuid
            ? "pumperLine active-curve"
            : "pumperLine";

    const loadChart = async () => {
        setProjectSavedChartSettings({
            temperature: isMetric
                ? curveInput.temperature + " °C"
                : ConvertCelsiusToFahrenheit(curveInput.temperature) + " °F",
            pressure: isMetric
                ? curveInput.pressure + " KPa"
                : ConvertKPaToPSI(curveInput.pressure).toFixed(2) + " PSI",
            gasFlowRate: isMetric
                ? curveInput.gasFlowRate + " e³Sm³/d"
                : ConvertGasFlowRateToImperial(curveInput.gasFlowRate).toFixed(2) +
                " MMSCFD",
            liquidFlowRate: isMetric
                ? curveInput.liquidFlowRate + " m³/d"
                : ConvertLiquidFlowRateToImperial(curveInput.liquidFlowRate) + " BBL/d",
            waterCutPercentage: curveInput.waterCutPercentage + " %",
            sandSize: isMetric
                ? curveInput.sandSize + " microns"
                : ConvertSandSizeToImperial(curveInput.sandSize) + " US Mesh Size",
            gasMolecularWeight:           
                (isMetric ? curveInput.gasMolecularWeight + " g/mol" : curveInput.gasMolecularWeight + " lb/lb mol"),
            gasPseudoCriticalPressure: isMetric
                ? curveInput.gasPseudoCriticalPressure + " kgf/cm²"
                : ConvertGasPseudoCriticalPressureToImperial(curveInput.gasPseudoCriticalPressure).toFixed(3) + " PSI",
            gasPseudoCriticalTemperature: isMetric
                ? curveInput.gasPseudoCriticalTemperature + " Kelvin"
                : ConvertGasPseudoCriticalTemperatureToImperial(curveInput.gasPseudoCriticalTemperature).toFixed(1) + " R",
            creationDate: props.children.createdDateString,
            createdBy: props.children.userName,
            recommendation: `${recommendation.inches}” ${recommendation.tradeName} ${recommendation.pressureClass} with ${recommendation.sandLock}`
        });

        await api.generateCurveByRecommendationGuid(props.children.recommendationGuid, props.token).then(response => {
            if (response.status === 200) {
                setSavedProjectChartData(
                    CreateCurve(response.data, [
                        curveInput.gasFlowRate,
                        curveInput.liquidFlowRate
                    ], isMetric)
                );
                setActiveProjectChartGuid(props.children.recommendationGuid);
                toast.success("Success: Curve loaded.",
                    {position: toast.POSITION.TOP_RIGHT});
                window.scrollTo(0, 0);
            }
        })
            .catch(e => {
                toast.error(`Error: ${e}.`,
                    {position: toast.POSITION.TOP_RIGHT});
            });
    };

    return (
        <>
            <tr>
                <td>
                    <div className={activeChart}>
                        <div className="curve-inner-container">
                            <div className="curve-column">{recommendation.inches}” {recommendation.tradeName} {recommendation.pressureClass} with {recommendation.sandLock}</div>
                            <div className="curve-column">
                                <ul
                                    style={{
                                        "list-style-type": "none",
                                        padding: 0,
                                        marginBottom: "8px"
                                    }}
                                >
                                    <li>
                                        Temperature:{" "}
                                        {isMetric
                                            ? curveInput.temperature + " °C"
                                            : ConvertCelsiusToFahrenheit(curveInput.temperature) +
                                            " °F"}
                                    </li>
                                    <li>
                                        Pressure:{" "}
                                        {isMetric
                                            ? curveInput.pressure + " KPa"
                                            : ConvertKPaToPSI(curveInput.pressure).toFixed(2) + " PSI"}
                                    </li>
                                    <li>
                                        Gas Flow Rate:{" "}
                                        {isMetric
                                            ? curveInput.gasFlowRate + " e³Sm³/d"
                                            : ConvertGasFlowRateToImperial(curveInput.gasFlowRate).toFixed(2) +
                                            " MMSCFD"}
                                    </li>
                                    <li>
                                        Liquid Flow Rate:{" "}
                                        {isMetric
                                            ? curveInput.liquidFlowRate + " m³/d"
                                            : ConvertLiquidFlowRateToImperial(
                                            curveInput.liquidFlowRate
                                        ) + " BBL/d"}
                                    </li>
                                    <li>
                                        Water Cut Percentage: {curveInput.waterCutPercentage} %
                                    </li>
                                    <li>Sand Lock: {ConvertSandLock(curveInput.sandLock)}</li>
                                    <li>
                                        Sand Size:{" "}
                                        {isMetric
                                            ? curveInput.sandSize + " microns"
                                            : ConvertSandSizeToImperial(curveInput.sandSize) +
                                            " US Mesh Size"}
                                    </li>
                                    <li>Sphere Size: {curveInput.sphereSize} inches</li>
                                    <li>
                                        Gas Molecular Weight: {curveInput.gasMolecularWeight}{isMetric ? " g/mol" : " lb/lbmol"}
                                    </li>
                                    <li>
                                        Gas Pseudo Critical Pressure: {isMetric 
                                        ? curveInput.gasPseudoCriticalPressure + " kgf/cm²"
                                        : ConvertGasPseudoCriticalPressureToImperial(curveInput.gasPseudoCriticalPressure).toFixed(3) + " PSI"}
                                    </li>
                                    <li>
                                        Gas Pseudo Critical Temperature: {isMetric ?
                                        curveInput.gasPseudoCriticalTemperature + " K"
                                        : ConvertGasPseudoCriticalTemperatureToImperial(curveInput.gasPseudoCriticalTemperature).toFixed(1) + " R"} 
                                    </li>
                                </ul>
                            </div>
                            <div className="curve-column">{props.children.userName}</div>
                            <div className="curve-column">{props.children.createdDateString}</div>
                            <div className="curve-load-button">
                                <Button
                                    color="danger"
                                    className="float-right"
                                    outline
                                    onClick={loadChart}
                                    size="md"
                                >
                                    Load Chart
                                </Button>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </>
    );
};

export default CurveLineWithUser;
