import * as Yup from "yup";
import { ConversionUtility } from "./ConversionUtility";

const metricValues = ConversionUtility(true);
const imperialValues = ConversionUtility(false);

export const metricValidationSchema = () =>
  Yup.object({
    pressure: Yup.number()
      .required("Required.")
      .min(metricValues.pressure.min)
      .max(metricValues.pressure.max),
    temperature: Yup.number()
      .required("Required.")
      .min(metricValues.temperature.min)
      .max(metricValues.temperature.max),
    gasFlowRate: Yup.number()
      .required("Required.")
      .min(metricValues.gasFlowRate.min)
      .max(metricValues.gasFlowRate.max),
    liquidFlowRate: Yup.number()
      .required("Required.")
      .min(metricValues.liquidFlowRate.min)
      .max(metricValues.liquidFlowRate.max),
    waterCutPercentage: Yup.number()
      .required("Required.")
      .min(metricValues.waterCutPercentage.min)
      .max(metricValues.waterCutPercentage.max),
    sandSize: Yup.number()
      .required("Required.")
      .min(metricValues.sandSize.min)
      .max(metricValues.sandSize.max),
    gasPseudoCriticalPressure: Yup.number()
      .required("Required.")
      .min(metricValues.gasPseudoCriticalPressure.min)
      .max(metricValues.gasPseudoCriticalPressure.max),
    gasPseudoCriticalTemperature: Yup.number()
      .required("Required.")
      .min(metricValues.gasPseudoCriticalTemperature.min)
      .max(metricValues.gasPseudoCriticalTemperature.max),
    gasMolecularWeight: Yup.number()
      .required("Required.")
      .min(metricValues.gasMolecularWeight.min)
      .max(metricValues.gasMolecularWeight.max)
  });

export const imperialValidationSchema = () =>
  Yup.object({
    pressure: Yup.number()
      .required("Required.")
      .min(imperialValues.pressure.min)
      .max(imperialValues.pressure.max),
    temperature: Yup.number()
      .required("Required.")
      .min(imperialValues.temperature.min)
      .max(imperialValues.temperature.max),
    gasFlowRate: Yup.number()
      .required("Required.")
      .min(imperialValues.gasFlowRate.min)
      .max(imperialValues.gasFlowRate.max),
    liquidFlowRate: Yup.number()
      .required("Required.")
      .min(imperialValues.liquidFlowRate.min)
      .max(imperialValues.liquidFlowRate.max),
    waterCutPercentage: Yup.number()
      .required("Required.")
      .min(imperialValues.waterCutPercentage.min)
      .max(imperialValues.waterCutPercentage.max),
    sandSize: Yup.number()
      .required("Required.")
      .min(imperialValues.sandSize.min)
      .max(imperialValues.sandSize.max),
    gasPseudoCriticalPressure: Yup.number()
      .required("Required.")
      .min(imperialValues.gasPseudoCriticalPressure.min)
      .max(imperialValues.gasPseudoCriticalPressure.max),
    gasPseudoCriticalTemperature: Yup.number()
      .required("Required.")
      .min(imperialValues.gasPseudoCriticalTemperature.min)
      .max(imperialValues.gasPseudoCriticalTemperature.max),
    gasMolecularWeight: Yup.number()
      .required("Required.")
      .min(imperialValues.gasMolecularWeight.min)
      .max(imperialValues.gasMolecularWeight.max)
  });
